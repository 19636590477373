import { get } from '@/utils/apiBase.js'
// 请求顶部分类数据
const getRankCategoryList = option => {
    return get('/apis/product/get_rank_category_list', option)
}

const rankList = option => {
    return get('/apis/project_zc/rank_list', option)
}

const detailsV2 = option => {
    return get('/apis/mall/category/details_v2', option)
}

// 创意排行榜列表
const ideaRankingList = option => {
    return get('/apis/project/idea_ranking_list', option)
}

// 获取众筹类目榜单
const getCategoryTopList = option => {
    return get('/apis/project/get_pro_category_top_list', option)
}
export default {
    getRankCategoryList,
    rankList,
    detailsV2,
    ideaRankingList,
    getCategoryTopList
}
