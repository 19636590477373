const state = () => ({
  showShare: false,
  share_info: {}
})

const mutations = {
  currentShareUpdate (state, update) {
    console.log(update)
    state[update.obj] = update.value
  },
  changeShareStatus (state, update) {
    state[update.obj] = !state[update.obj]
  }
}

const actions = {
  getMallShare ({ commit }, option) {
    return new Promise((resolve) => {
      const searchParams = {
        url: '/mall_share',
        method: 'get',
        props: option
      }
      this.$getData(searchParams).then(res => {
        res.weibo_des = res.weibo_des.replace(`“${res.share_url}”`, res.weibo_share_url)
        res.msg_des = res.msg_des.replace(`“${res.share_url}”`, res.weibo_share_url)
        commit({
          type: 'currentUpdate',
          obj: 'share_info',
          value: res
        })
        resolve(res)
      })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
