import Layout from '@/layout/default'
const activityRouter = {
  // 搜索
  path: '/search',
  name: 'search',
  component: Layout,
  children: [
    {
      path: '',
      name: '搜索',
      meta: {
        title: '搜索',
        isShowNav:true
      },
      component: () => import('@/views/search/index.vue')
    },
  ]
}

export default activityRouter
