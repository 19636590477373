import Layout from '@/layout/default'
const HomeRouter = {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
        {
            path: '',
            name: 'center',
            meta: {
                isShowNav: true,
                isShowBtn: true
            },
            component: () => import('@/views/home/home.vue')
        }
    ]
}

export default HomeRouter
