import { post, get } from '@/utils/apiBase.js'

const getWeiboInitInfo = option => {
  return post('/main/ajax_get_weibo_init_info', option)
}
const signPackage = option => {
  return get('/main/ajax_sign_package', option)
}

export default {
  getWeiboInitInfo,
  signPackage
}
