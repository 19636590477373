import { get } from '@/utils/apiBase.js';

const getSearchAll = (option) => {
  return get('/search/all', option);
};
const getMdsearchComprehensive = (option) => {
  return get('/apis/mdsearch/category_list', option);
};
const getSearchKeywordsBanner = (option) => {
  return get('/apis/hotspot/search_keywords_banner', option);
};
const getProject_zcRrank_list = (option) => {
  return get('/apis/project_zc/rank_list', option);
};
const getCategoryDetails = (option) => {
  return get('/apis/mall/category/details_v2', option);
};
const getSearchKeyWords = (option) => {
  return get('/hotspot/search_key_words', option);
};
const getHotList = (option) => {
  return get('/apis/mdsearch/hot_list', option);
};
const dropdownList = (option) => {
  return get('/apis/mdsearch/dropdown_list', option);
};
export default {
  getSearchAll,
  getMdsearchComprehensive,
  getSearchKeywordsBanner,
  getProject_zcRrank_list,
  getCategoryDetails,
  getSearchKeyWords,
  getHotList,
  dropdownList
};
