import Layout from '@/layout/default'

const ideaRouter = {
  // 创意
  path: '/idea',
  name: 'idea',
  component: Layout,
  children: [{
      path: 'create', // H5发起创意
      name: 'idea-create-index',
      meta: {
        isShowHeader: true
      },
      component: () => import('@/views/idea/create/index.vue')
    },
    {
      path: 'client/detail/:id', // app内嵌-创意
      name: 'idea-client-detail',
      meta: {
        isShowHeader: true,
        isShowNav: true
      },
      component: () => import('@/views/idea/client/detail/_id.vue')
    },{
      path: 'client/detail_v2/:id', // app内嵌-创意-过渡v2
      name: 'idea-client-detail-v2',
      meta: {
        isShowHeader: true,
        isShowNav: true
      },
      component: () => import('@/views/idea/client/detail_v2/_id.vue')
    }, {
      path: ':id', // 创意详情
      name: 'idea-detail',
      meta: {
        isShowNav: true,
        isShowBtn: false
      },
      component: () => import('@/views/idea/_id.vue')
    }
  ]
}

export default ideaRouter
