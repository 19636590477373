//https://m.modian.com/project_preview?token=1690e52010a96d05df480234514e60c0&temp_id=105855&id=122701
import Preview from '@/layout/preview'
const projectPreviewRouter = {
    path: '/project_preview',
    name: 'project_preview',
    component: Preview,
    children: [
        {
            path: '', // 众筹详情
            name: 'project_preview',
            meta: {
                isShowHeader: true,
                isShowNav: true,
                isShowBtn: false
            },
            component: () => import('@/views/project/_id.vue')
        }
    ]
}

export default projectPreviewRouter
