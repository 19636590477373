import { getExpressCompanyList } from "@/api/index"
const state = () => ({
  info: {},
  expressCompanyList: []
})

const mutations = {
  setValue (state, update) {
    state[update.obj] = update.value
  }
}

const actions = {
  async GetExpressCompanyList({ state, commit }) {
    if (state.expressCompanyList && state.expressCompanyList.length <= 0) {
        const res = await getExpressCompanyList()
        if (res.status == 0) {
            commit('setValue', {
              obj: 'expressCompanyList',
              value: res.data
            })
        }
    }
}
}

export default {
  state,
  mutations,
  actions
}
