import { get, post } from '@/utils/apiBase.js'
// 更新详情
const getProjectUpdateDetail = option => {
    return post('/apis/project_zc/update_detail', option)
    // return post('/honor/product/update_detail', option)
}
// 投票 md_topic/do_vote
// const doVote = option => {
//     return post('/honor/main/do_vote', option)
// }
const projectVote = option => {
    return post('/apis/project/vote', option)
}

// 获取更新列表
const getUpdateList = option => {
    return get('/apis/project_zc/update_list', option)
}
// 获取更新详情
const getUpdateDetail = option => {
    return post('/honor/product/update_detail', option)
}
// 获取更新详情-点赞的人
const getDigUserList = option => {
    return post('/honor/main/dig_user_list', option)
}

// 获取新的抽奖详情
const getNewDrawInfo = option => {
    return get('/apis/project/cp_admin/get_activity_card_info', option)
}

// 小红书种草活动-----获取已提交的凭证
const getProjectRednoteProof = option => {
    return get('/apis/project/rednote_proof', option)
}
// 小红书种草活动-----提交凭证
const submitRednoteProof = option => {
    return post('/apis/project/submit_rednote_proof', option)
}
// 获取众筹更新小红书种草信息
const getRednoteInfo = option => {
    return get('/apis/project/get_rednote_info', option)
}
// 小红书种草活动-----上传地址
const uploadRednoteAddressInfo = option => {
    return post('/apis/project/submit_rednote_winner_address', option)
}
export default {
    getProjectUpdateDetail,
    projectVote,
    getUpdateList,
    getUpdateDetail,
    getDigUserList,
    getNewDrawInfo,
    getProjectRednoteProof,
    submitRednoteProof,
    getRednoteInfo,
    uploadRednoteAddressInfo
}
