import Layout from '@/layout/default.vue'
import RouterView from '@@/RouterView' 

const digitalRouter = {
  // 数字藏品
  path: '/digital',
  name: 'digital',
  component: Layout,
  children: [
    {
      path: 'detail/:id', // 详情
      name: 'digital-detail',
      component: () => import('@/views/digital/detail/_id.vue')
    },
    {
      path: 'activity', // 藏品合成-cba活动
      name: 'cba',
      component: RouterView,
      children: [
        {
          path: 'cba', // 藏品合成-cba一期
          name: 'cba',
          component: () => import('@/views/digital/activity/info/cba.vue')
        },
        {
          path: 'cba/25437429761', // 藏品合成-cba二期
          name: 'cba25437429761',
          component: () => import('@/views/digital/activity/info/cba25437429761.vue')
        },
        {
          path: ':id', // 藏品合成-模板
          name: 'activity',
          component: () => import('@/views/digital/activity/_id.vue')
        },
        {
          path: 'info', // 藏品合成-定制路径
          name: 'info',
          component: RouterView,
          children: [
            {
              path: '119967', // 十二生肖
              name: 'shiershengxiao',
              component: () => import('@/views/digital/activity/info/119967.vue')
            },
            {
              path: '119967_', // 十二生肖补救
              name: 'shiershengxiaofix',
              component: () => import('@/views/digital/activity/info/119967_.vue')
            }
          ]
        }
      ],
    },
    {
      path: 'preview', // 模型预览
      name: 'preview',
      meta: {
        isShowHeader: true,
        isShowNav: true
      },
      component: RouterView,
      children: [
        {
          path: 'model',
          name: 'model',
          component: () => import('@/views/digital/preview/model.vue')
        }
      ]
    },
    {
      path: 'list', //藏品
      meta: {
        isShowHeader: false,
        isShowNav: true
      },
      name: 'user-nftlist',
      component: () => import('@/views/digital/list.vue')
    },
  ],
}

export default digitalRouter
