import router from '@/router'
import env from 'md-base-tools/env'
import domain from "@/service/domain";

import { getSchemeUrl } from "@/utils/toolsApi";
import { mdToast } from '../utils/tools';

const MDRouter = {

    // 好像没用？
    goHome: function () {
        router.push('/')
    },

    // 好像没用？
    goBack: function () {
        if (!document.referrer && !(window.history.state && window.history.state.back)) {
            router.push('/')
        } else {
            router.go(-1)
        }
    },

    // ****************** 众筹 ********************

    // 去更新列表
    goUpdateList: function (query) {
        router.push({
            path: '/project/update/list',
            query: query
        })
    },
    // 去众筹更新详情
    goUpdateDetail: function (id, query) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://update_detail?id=${id}`,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('UpdateDetail', {
                        id: id,
                        query: query
                    })
                } else {
                    // router.push({
                    //     path: `/project/update/detail/${id}`
                    // })
                    location.href = `${domain.wap}/project/update/detail/${id}`
                }
            }
        })
    },
    // 去众筹详情
    goProInfo: function (id, query) {
        const queryString = getUrlParamsString(query)
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://project?id=${id}${queryString ? "&" + queryString : ''}`,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('ProInfo', {
                        pro_id: id,
                        query: query
                    })
                } else {
                    router.push({
                        path: `/project/${id}.html`,
                        query: query
                    })

                }
            }
        })
    },
    // 众筹项目列表
    goProjectList: function(query) {
        // eslint-disable-next-line
        graft.app({
            functionName: "openController",
            functionParams: `md://projectList`,
            webFun: () => {
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('ProjectList')
                } else {
                    router.push({
                        path: '/project/list',
                        query: query
                    })
                }
            }
        })
    },
    // ****************** 电商 ********************
    // 去电商详情
    goMallInfo: function (id, query) {
        const queryString = getUrlParamsString(query)
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://mall_detail?id=${id}${queryString ? '?' + queryString : ''}`,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('MinaWebview', {
                        url: `${domain.wap}/product/${id}.html${queryString ? '&' + queryString : ''}`
                    })
                } else {
                    location.href = `${domain.wap}/product/${id}.html${queryString ? '?' + queryString : ''}`
                }
            }
        })
    },
    // 去创意详情
    goIdeaInfo: function(id, query) {
        const queryString = getUrlParamsString(query)
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://idea?id=${id}${queryString ? '&' + queryString : ''}`,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('IdeaDetail', {
                        idea_id: id,
                        query: query
                    })
                } else {
                    router.push({
                        path: `/idea/${id}`,
                        query: query
                    })
                }
            }
        })
    },
    // 去抽卡详情
    goLuckycardsInfo: function(id, query) {
        const queryString = getUrlParamsString(query)
        const url = `${domain.wap}/luckycards/fastsell/detail/${id}${queryString ? '?' + queryString : ''}`
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: url,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('LuckycardsDetail', {
                        id: id
                    })
                } else {
                    router.push({
                        path: `/luckycards/fastsell/detail/${id}`,
                        query: query
                    })
                }
            }
        })
    },
    // 去抽卡合集列表
    goLuckycardsCollectList: function(id, query) {
        const url = `${domain.wap}/luckycards/fastsell/collect_list/${id}`
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: url,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('LuckycardsCollectList', {
                        id: id
                    })
                } else {
                    router.push({
                        path: `/luckycards/fastsell/collect_list/${id}`,
                        query: query
                    })
                }
            }
        })
    },

    // 去动态详情
    goFeedInfo: function(id, query) {
        const queryString = getUrlParamsString(query)
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://post_detail?id=${id}`,
            webFun: () => {
                location.href = `${domain.wap}/comm/feed/${id}.html${queryString ? '?' + queryString : ''}`
            }
        })
    },
    // 去话题详情
    goTopicInfo: function(id, query) {
        const queryString = getUrlParamsString(query)
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://trend_detail?id=${id}`,
            webFun: () => {
                location.href = `${domain.wap}/comm/topic/${id}.html${queryString ? '?' + queryString : ''}`
            }
        })
    },
    // ****************** 交易 ********************
    // 众筹订单详情
    goProOrderDetail: function (id) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://order?id=${id}`,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('ProOrder', {
                        id: id
                    })
                } else {
                    router.push({
                        path: `/order/project_order_detail`,
                        query: {
                            order_id: id
                        }
                    })
                }
            }
        })
    },
    // 电商订单详情
    goMallOrderDetail: function (id) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://mall_order?id=${id}`,
            webFun: () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('MallOrder', {
                        order_id: id
                    })
                } else {
                    router.push({
                        path: `/order/product_order_detail`,
                        query: {
                            order_id: id
                        }
                    })
                }
            }
        })
    },
    // 查看物流
    goLogisticsInfo: function (query) {
        if (!query.express_no) {
            return mdToast('未获取到物流单号')
        }
        if (!query.express_name) {
            return mdToast('未获取到物流公司名称')
        }

        let type = 'wap'
        let url = '/order/logistics/info?express_no=' + query.express_no + '&express_no_mix=' + query.express_no_mix + '&express_name=' + query.express_name

        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `${type && domain[type] ? domain[type] + url : url}`,
            webFun: () => {
                router.push({
                    path: '/order/logistics/info',
                    query: {
                        express_no: query.express_no,
                        express_no_mix: query.express_no_mix,
                        express_name: query.express_name
                    }
                })
            }
        })
    },
    // 联系客服
    goChat: function (query) {
        if (!query.pro_type) {
            return mdToast('未获取到项目类型')
        }
        if (!query.pro_id) {
            return mdToast('未获取到项目ID')
        }
        if (query.pro_type == '101') {
            if (!query.cp_user_id) {
                return mdToast('未获取到发起人ID')
            }
        } if (query.pro_type == '901') {
            if (!query.shop_id) {
                return mdToast('未获取到商品ID')
            }
        }
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://chat?pro_type=${query.pro_type}${query.pro_type == '101' ? '&cp_user_id=' + query.cp_user_id : '&shop_id=' + query.shop_id}&pro_id=${query.pro_id}`,
            webFun: () => {
                const msg = "请移驾到「摩点App」中联系客服吧，小姐姐会快速回复你呦～";
                return mdToast(msg);
            }
        })
    },

    // 联系智齿客服
    goZCChat: function (query) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://zc_chat?scene=${query.scene}${query.des ? '&des=' + query.des : ''}`,
            webFun: () => {
                const msg = "请移驾到「摩点App」中联系客服吧，小姐姐会快速回复你呦～";
                return mdToast(msg);
            }
        })
    },
    // ****************** 用户 ********************
    // 去他的主页
    toUserhome: function (id) {
        router.push(`/user/homePage/${id}`);
    },
    // 去看好项目
    goLikePro: function (query) {
        router.push({
            path: "/user/like_project",
            query: query
        })
    },
    // 支持项目
    goSupportProject: function (query) {
        router.push({
            path: "/user/support_project",
            query: query
        })
    },
    // 关注者列表
    goFollow: function (query) {
        router.push({
            path: "/user/follow",
            query: query
        })
    },
    // 数字藏品列表
    goDigitalList: function (query) {
        router.push({
            path: "/digital/list",
            query: query
        })
    },
    // 去个人中心
    goUserCenter: function (query) {
        router.push({
            path: "/user/center",
            query: query
        })
    },
    // 去申诉
    goAppeal: function(obj) {
        // 申诉
        const url = `/after_sale/appeal/${obj.aftersale_id}?order_id=${obj.order_id}`
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `${domain['wap'] + url}`,
            webFun: () => {
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('AftersaleAppeal', {
                        aftersale_id: obj.aftersale_id,
                        pro_id: obj.pro_id
                    })
                } else {
                    router.push(url)
                }
            },
        });
    },
    // ****************** 通用 ********************
    Link: function (url, type) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `${type && domain[type] ? domain[type] + url : url}`,
            webFun: () => {
                if (url.indexOf('http') > -1) {
                    location.href = url
                } else {
                    router.push(url)
                }
            },
        });
    },
    GoBack: function () {
        window.location.reload()
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "closeController",
            webFun: () => {
                window.history.go(-1);
            },
        });
    },
    MinaRouter: function(type, query) {
        if (env.isWxApp()) {
            let link_url = ''
            const queryString = getUrlParamsString(query)
            switch (type) {
                case "ProInfo": // 众筹详情
                    link_url = '/pages/project-detail/project-detail'
                    break;
                case "ProjectList": // 众筹项目列表
                    link_url = '/pages/project_list/project_list'
                    break;
                case "UpdateDetail": // 众筹更新详情
                    link_url = '/pages/projectPages/update-detail/update-detail'
                    break;
                case "IdeaDetail": // 创意详情
                    link_url = '/pages/ideaPages/idea_detail/idea_detail'
                    break;
                case "LuckycardsDetail": // 抽卡详情
                    link_url = '/pages/luckycards/detail/detail'
                    break;  
                case "LuckycardsCollectList": // 抽卡合集列表
                    link_url = '/pages/luckycards/collect_list/collect_list'
                    break; 
                case "MallOrder": // 电商订单详情
                    link_url = '/pages/order_pages/mall_order/order_detail'
                    break;  
                case "ProOrder": // 众筹订单详情
                    link_url = '/pages/order_pages/project_order/order_detail'
                    break;
                case "AftersaleAppeal": // 去申诉
                    link_url = '/pages/aftersale/appeal/appeal'
                    break; 
                case "ProDraw": // 抽奖详情
                    link_url = '/pages/prize/detail/detail'
                    break; 
                case "MinaWebview": // 小程序内嵌
                    link_url = '/pages/content/content';
                    break;
                default: 
                    link_url = '/pages/index/index'
            }
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
                url: link_url + (queryString ? '?' + queryString : '')
            })
        }
    },
    // 抽奖详情
    goDraw: function(id) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://prizeDetail?id=${id}`,
            webFun: async () => {
                //小程序
                if (env.isWxApp()) {
                    MDRouter.MinaRouter('ProDraw', {
                        activity_id: id
                    })
                } else {
                    const param = {
                        path: "/pages/index/index",
                        query: `nextUrl=pages/prize/detail/detail?activity_id=${id}`,
                    }
                    const option = { jump_wxa: JSON.stringify(param) };
                    const url = await getSchemeUrl(option);
                    location.href = url;

                }
            }
        })
    },
    // 小红书上传凭证
    goRednoteUpload: function(id) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `md://redNote?id=${id}`,
            callBack: e => {
                if (e.errorCode == 0) {
                    if(Number(e.data.submit_success) == 1){
                        window.location.reload();
                    }
                }
            },
            webFun: async () => {
                router.push({
                    path: '/project/update/red_note',
                    query: {
                        id: id
                    }
                })
            }
        })



        /*
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `${domain['wap']}/project/update/red_note?id=${id}`,
            callBack: e => {
                if (e.errorCode == 0) {
                    if (Number(e.data.submit_success) == 1) {
                        window.location.reload();
                    }
                }
            },
            webFun: async () => {
                router.push({
                    path: '/project/update/red_note',
                    query: {
                        id: id
                    }
                })
            }
        })
        */
    },

    // 去中奖名单
    goRednotePrize: function(id) {
        // eslint-disable-next-line no-undef
        graft.app({
            functionName: "openController",
            functionParams: `${domain['wap']}/project/update/red_note_prize?id=${id}`,
            webFun: async () => {
                router.push({
                    path: '/project/update/red_note_prize',
                    query: {
                        id: id
                    }
                })
            }
        })
    }
}
export default MDRouter

//校验path路径跳转
export const rightFormatPath = function(path) {
    path = path.charAt(0) == '/' ? path : '/' + path
    return path;
}
// 将map【query参数】转换为字符串格式
export const getUrlParamsString = function(params) {
    if (typeof params != 'object') return ''
    let arr = [];
    for (const key in params) {
        arr.push(`${key}=${params[key]}`)
    }
    return arr.length ? arr.join('&') : ''
}

// 将【字符串参数】转换为map格式
export const getUrlParamsMapFromString = function (paramsString) {
    const params = {}
    paramsString.split('&').forEach(queryItem => {
        const [key, value] = queryItem.split('=');
        if (key) {
            params[decodeURIComponent(key)] = decodeURIComponent(value || '');
        }
    })
    return params;
}

// 获取url?后参数，输出为map格式
export const getUrlParamsMapFromUrl = function(url) {
    if (url.indexOf('?') !== -1) {
        return getUrlParamsMapFromString(url.split('?')[1])
    }
    return {}
}

// 匹配URL及内链 
export const getUrlType = function(url) {
    // 众筹详情正则
    let regPro = /^http[s]?:\/\/(testm|m|zhongchou|testzhongchou).*modian[inc]?.*?\.com\/(item|project)\/([0-9]+).*?$/;
    // 商品详情正则
    let regMall = /^http[s]?:\/\/(testm|m).*modian[inc]?.*?\.com\/(product)\/([0-9]+).*?$/;
    // 更新详情正则
    let regUpdate = /^http[s]?:\/\/(testm|m|zhongchou|testzhongchou).*modian[inc]?.*?\.com\/(project\/update\/detail|p\/update_detail)\/([0-9]+).*?$/;
    // 创意详情正则
    let regIdea = /^http[s]?:\/\/(testm|m).*modian[inc]?.*?\.com\/(idea)\/([0-9]+).*?$/;
    // 抽卡机详情
    let regLuckyCardDetail = /^http[s]?:\/\/(testm|m).*modian[inc]?.*?\.com\/luckycards\/fastsell\/detail\/([0-9]+).*?$/;
    // 动态详情正则
    let regPost = /^http[s]?:\/\/(m|zhongchou).*modian[inc]?.*?\.com\/(dongtai\/detail|common\/feed\/[0-9]).*?$/;
    // 话题详情正则
    let regTopic = /^http[s]?:\/\/(m|zhongchou).*modian[inc]?.*?\.com\/(topic\/topic_list|common\/topic\/[0-9]).*?$/;

    if (regPro.test(url) ||
      url.indexOf('md://project') > -1) { //项目详情页
      return 'ProInfo'
    }
    if (regMall.test(url) ||
      url.indexOf('md://mall_detail') > -1) { //电商详情页
      return 'MallInfo'
    }
    if (regUpdate.test(url) ||
      url.indexOf('md://update_detail') > -1) { //更新详情页
      return 'UpdateDetail'
    }
    if (regIdea.test(url) ||
      url.indexOf('md://idea') > -1) { //创意
      return 'IdeaDetail'
    }
    if (regLuckyCardDetail.test(url)) { // 抽卡机详情
      return 'LuckycardsDetail'
    }
    if (regPost.test(url) ||
      url.indexOf('md://post_detail') > -1) { // 动态
      return 'FeedInfo'
    }
    if (regTopic.test(url)) { // 话题详情
        return 'TopicInfo'
    }
}
//---------------参数解析-----------------
export const getIDParams = function(url) {
    const id = url.match(/\/(\d+)\.*?/);
    return id ? id[1] : undefined
}

// 识别链接
export const urlFormatToJump = function(url) {
    var type = getUrlType(url)
    let paramsMap = getUrlParamsMapFromUrl(url);
    let id = getIDParams(url) || paramsMap.id
    console.log('type', type)
    console.log('paramsMap', paramsMap)
    console.log('id', id)
    switch(type) {
        case 'ProInfo': 
            MDRouter.goProInfo(id, paramsMap);
            break;
        case 'MallInfo':
            MDRouter.goMallInfo(id, paramsMap);
            break;
        case 'UpdateDetail':
            MDRouter.goUpdateDetail(id, paramsMap);
            break;
        case 'IdeaDetail':
            MDRouter.goIdeaInfo(id, paramsMap);
            break;
        case 'LuckycardsDetail':
            MDRouter.goLuckycardsInfo(id, paramsMap);
            break;
        case 'FeedInfo':
            MDRouter.goFeedInfo(id, paramsMap);
            break;
        case 'TopicInfo':
            MDRouter.goTopicInfo(id, paramsMap);
            break;
        default:
            MDRouter.Link(url);  
    }
}

export const interceptLink = function(url) {
    return new Promise(resolve => {
        // 抽奖详情
        let regDraw = /^\/draw\/([0-9]+).*?$/;
        let matches = url.match(regDraw)
        if (regDraw.test(url) && matches && matches.length >= 2) {
            router.push({
                path: "/board",
                query: {
                    type: 'draw',
                    id: matches[1]
                }
            })
        } else {
            resolve()
        }
    })
    
}
