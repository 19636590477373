import { get, post } from '@/utils/apiBase.js'
// 抽卡机

// 项目详情
const getExtractCardDetail = option => {
  return get('/apis/luckycards/extract_card/detail', option)
}
// 获取抽卡规则
const getExtractCardRule = option => {
  return get('/apis/luckycards/extract_card/rule', option)
}
// 抽卡开奖
const cardPrize = option => {
  return post('/apis/mdorder/card_prize', option)
}
// 抽卡机图鉴列表
const getCardFileList = option => {
  return get('/apis/luckycards/extract_card/card_file_list', option)
}
// 抽卡流程-卡册图鉴
const getUserCollect = option => {
  return get('/apis/luckycards/extract_card/user_collect', option)
}
// 获取项目等级
const getCardLevel = option => {
  return get('/apis/luckycards/extract_card/level', option)
}
// 获取电商支付信息
const getMallOrderPayInfo = option => {
  return get('/mall/order/payinfo', option)
}
// 抽卡流程-抽卡机项目列表页
const getExtractCardList = option => {
  return get('/apis/luckycards/extract_card/list', option)
}

// 检测是否购买过抽卡机订单
const CheckHasBought = option => {
  return get('/apis/mdorder/check_card', option)
}

// 卡包-选择卡片-草稿
const SelectCardDraft = option => {
  return post('/apis/card-wallet/select/card/draft', option)
}
// 卡包-删除草稿
const SelectCardDraftDel = option => {
  return post('/apis/card-wallet/draft/del', option)
}
// 卡包-草稿合并
const SelectCardDraftMerge = option => {
  return post('/apis/card-wallet/draft/merge', option)
}

// 卡包-获取列表活动已选数量
const GetSelectActivityCardNums = option => {
  return get('/apis/card-wallet/select/activity/card/num', option)
}
// 合集列表
const GetCollectList = option => {
  return get('/apis/luckycards/extract_card/collect/list', option)
}
// 获取抽卡列表广告位
const GetLuckycardsHomeBanner = option => {
  return get('/apis/hotspot/luckycards_home_banner', option)
}
// 获取抽卡概率
const GetProbability = option => {
  return get('/apis/luckycards/extract_card/probability_detail', option)
}
// 抽卡机预约
const ExtractCardSubscribe = option => {
  return post('/apis/luckycards/extract_card/subscribe', option)
}
// 抽卡机下单规则
const ExtractCardOrderRules = option => {
  return get('/apis/luckycards/extract_card/order/rules', option)
}
export default {
    getExtractCardDetail,
    getExtractCardRule,
    cardPrize,
    getCardFileList,
    getUserCollect,
    getCardLevel,
    getMallOrderPayInfo,
    getExtractCardList,
    CheckHasBought,
    SelectCardDraft,
    SelectCardDraftDel,
    SelectCardDraftMerge,
    GetSelectActivityCardNums,
    GetCollectList,
    GetLuckycardsHomeBanner,
    GetProbability,
    ExtractCardSubscribe,
    ExtractCardOrderRules
}
