import { getAddressList } from "@/api/index"

const state = () => ({
    addressList: [] //省市区列表
})
const mutations = {
    currentAddressListUpdate (state, update) {
      state.addressList = update
    }
}
  
const actions = {
    async getAddressList({ state, commit }) {
        function delEmptyList (e) {
            e.map((r) => {
              if (r.list && r.list.length) {
                delEmptyList(r.list)
              } else {
                delete r.list
              }
            })
        }
        if (state.addressList && state.addressList.length <= 0) {
            const res = await getAddressList({ json_type: '1' })
            if (res.status == 0) {
                delEmptyList(res.data)
                commit('currentAddressListUpdate', res.data)
            }
        }
    }
}

export default ({
    state,
    mutations,
    actions
})
