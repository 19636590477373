import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [_createVNode(_component_router_view, {
      class: _normalizeClass({
        active: $options.user_center_show && $options.isShowHeader,
        activeNav: $options.isShowNav,
        activePro: $options.user_center_show && $options.isShowHeader && $options.isShowNav,
        viewBox: true
      })
    }, null, 8, ["class"])]),
    _: 1
  });
}