import Cookies from 'js-cookie'
const TokenKey = 'MDUSERTOKEN'
const IconKey = 'MDUSERINFO'
import { goToLogin as goLogin } from 'md-base-tools/apiClient/redirect'
import store from '../store'

import env from "md-base-tools/env";

export function getToken (key = TokenKey) {
  if (env.isWxApp()) {
    key = 'MDUSERTOKEN_Mina'
  }
  return Cookies.get(key) || localStorage.getItem(key)
}

export function getIcon (key = IconKey) {
  return Cookies.get(key) || localStorage.getItem(key)
}

// 跳转登录方法
export function goToLogin () {
  goLogin('m', process.env.VUE_APP_ENV)
}
// 更新用户登录信息
export async function updateUserInfo () {
  if (!store.state.user.userId) {
    await store.dispatch('getUserInfo')
  } else {
    store.commit('currentUser')
  }
}
