import layerdev from '@/service/layerdev'
import env from 'md-base-tools/env'

const lowerdev = {
  addbind (element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false)
    } else if (element.attachEvent) {
      element.attachEvent('on' + type, handler)
    } else {
      element['on' + type] = handler
    }
  },
  removebind (element, type, handler) {
    if (element.removeEventListener) {
      element.removeEventListener(type, handler, false)
    } else if (element.detachEvent) {
      element.detachEvent('on' + type, handler)
    } else {
      element['on' + type] = null
    }
  },
  hasClass (obj, cls) {
    const objClassLst = obj.className.split(/\s+/)
    return objClassLst.some(ele => cls === ele)
  },
  addClass (obj, cls) {
    if (!this.hasClass(obj, cls)) {
      const objClass = obj.className
      const clsPlace = objClass !== '' ? ' ' : ''
      obj.className = objClass + clsPlace + cls
    }
  },
  removeClass (obj, cls) {
    if (this.hasClass(obj, cls)) {
      let objClass = ' ' + obj.className + ' '
      objClass = objClass.replace(/(\s+)/gi, ' ')
      let holdClass = objClass.replace(' ' + cls + ' ', ' ')
      holdClass = holdClass.replace(/(^\s+)|(\s+$)/g, '')
      obj.className = holdClass
    }
  },
  siblings (obj) {
    const nodes = []
    const than = obj
    while ((obj = obj.previousSibling)) {
      if (obj.nodeType === 1) {
        nodes.push(obj)
        break
      }
    }
    obj = than
    while ((obj = obj.nextSibling)) {
      if (obj.nodeType === 1) {
        nodes.push(obj)
        break
      }
    }
    return nodes
  },
  // toast (args, cancelBack, confirmCallBack) {
  //   const opt = {
  //     popup: '',
  //     msg: '',
  //     success: '确定',
  //     type: 'toast',
  //     title: ''
  //   }
  //   if (typeof args === 'object') {
  //     if (Array.isArray(args)) {
  //       throw new Error('lowerdev.toast: props(args) must be an array')
  //     }
  //     Object.assign(opt, args)
  //   } else {
  //     if (args) opt.msg = args
  //   }
  //   if (!opt.msg) {
  //     return false
  //   }
  //   layerdev.init(opt, {}, cancelBack, confirmCallBack)
  //   return layerdev
  // },
  confirm (args, callBack, cancelBack) {
    const opt = {
      msg: '您确定要执行该项操作？',
      success: '确定',
      cancel: '取消',
      type: 'confirm',
      title: ''
    }
    if (typeof args === 'object') {
      if (Array.isArray(args)) {
        throw new Error('lowerdev.confirm: props(args) must be an array')
      }
      Object.assign(opt, args)
    } else {
      if (args) opt.msg = args
    }
    layerdev.init(opt, {}, cancelBack, callBack)
  },
  holdon (msg) {
    const opt = {
      type: 'holdon'
    }
    if (msg) opt.msg = msg
    layerdev.init(opt)
  },
  holdoff () {
    layerdev.closeHoldon()
  },
  doTabs (args, callBack) {
    if (!args.el) {
      throw new Error('lowerdev.doTabs: props(args) is not defined')
    }
    const opt = {
      style: 'btn-primary',
      event: 'click'
    }
    Object.assign(opt, args)
    const el = document.getElementById(opt.el)
    const tabsLi = el.querySelector('.tabs-nav').children
    const tabsCont = el.querySelectorAll('.tabs-cont')
    for (let idx = 0, len = tabsLi.length; idx < len; idx++) {
      this.addbind(tabsLi[idx], opt.event, () => {
        if (!this.hasClass(tabsLi[idx], opt.style)) {
          // eslint-disable-next-line
          ;[].forEach.call(tabsLi, ele => {
            ele.className = ''
          })
          ;[].forEach.call(tabsCont, ele => {
            ele.style = ''
          })
          tabsLi[idx].className = opt.style
          tabsCont[idx].style.display = 'block'
          typeof callBack === 'function' && callBack(tabsLi[idx].getAttribute('data-type'))
        }
      })
    }
    const idx = Number(el.getAttribute('data-index')) || 0
    tabsLi[idx].className = opt.style
    tabsCont[idx].style.display = 'block'
  },
  openWin (url, target) {
    if (!url) {
      throw new Error('lowerdev.openWin: props(url) is not defined')
    }
    if (target) {
      let openWin = document.getElementById('openWin')
      if (!openWin) {
        const children = document.createElement('span')
        openWin = document.createElement('a')
        openWin.id = 'openWin'
        openWin.appendChild(children)
        document.body.appendChild(openWin)
      }
      openWin.href = url
      openWin.target = target
      openWin.children[0].click()
    } else {
      location.href = url
    }
  },
  gotoWin (msg, url, target) {
    if (msg) this.toast(msg)
    setTimeout(() => {
      this.openWin(url, target)
    }, 2000)
  },
  closeWin () {
    // eslint-disable-next-line
    opener = null
    open('', '_self')
    close()
  }
}

lowerdev.dynamicScript = (url, callBack) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.defer = true
  script.async = true
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
  if (typeof callBack === 'function') {
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (/complete|loaded/.test(script.readyState)) {
          script.onreadystatechange = null
          callBack()
        }
      }
    } else {
      script.onload = () => {
        callBack()
      }
    }
  }
}

lowerdev.scrollTo = offsetY => {
  offsetY = offsetY || 0
  const cosParameter = (scrollY - offsetY) / 2
  const scrollDuration = 300
  let scrollCount = 0
  let oldTimestamp = performance.now()

  function stepEach (newTimestamp) {
    scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
    if (scrollCount >= Math.PI) {
      scrollTo(0, offsetY)
      return false
    }
    scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)) + offsetY)
    oldTimestamp = newTimestamp
    requestAnimationFrame(stepEach)
  }
  requestAnimationFrame(stepEach)
}

lowerdev.checkSystemNotice = () => {
  if (env.isModianIos()) {
    // eslint-disable-next-line
    webkit.messageHandlers.checkSystemNotice.postMessage(null)
  } else {
    // eslint-disable-next-line
    appInterface.checkSystemNotice(null)
  }
}

lowerdev.openWeChatShare = str => {
  str = JSON.stringify(str)
  if (env.isModianIos()) {
    // eslint-disable-next-line
    webkit.messageHandlers.shareToWeChat.postMessage(str)
  } else {
    // eslint-disable-next-line
    appInterface.shareToWeChat(str)
  }
}

lowerdev.openShare = str => {
  str = JSON.stringify(str)
  if (env.isModianIos()) {
    // eslint-disable-next-line
    webkit.messageHandlers.share.postMessage(str)
  } else {
    // eslint-disable-next-line
    appInterface.share(str)
  }
}

lowerdev.openUrl = (type, prop, render) => {
  let h5Link = '/'
  if (type !== 'product') {
    h5Link += 'product/'
  }
  if (type) {
    h5Link += type
  }
  if (typeof prop === 'object' && !Array.isArray(prop) && Object.keys(prop).length) {
    const paras = []
    Object.keys(prop).forEach(key => {
      paras.push(key + '=' + prop[key])
    })
    h5Link += '?' + paras.join('&')
  } else if (prop) {
    h5Link += '/' + prop + '.html'
  }
  if (type === 'product' && render) {
    h5Link += '?render_model=' + render
  }
  let appLink = ''
  switch (type) {
    case 'allin':
      appLink = prop
      break
    case 'product':
      appLink = 'mall_detail?id=' + prop
      break
    case 'coupon':
      appLink = 'my_couponList'
      break
    case 'coupon/center':
      appLink = 'coupon_center'
      break
    case 'shop':
      appLink = 'mall_shopProList?id=' + prop
      break
    case 'brand':
      appLink = 'mall_brandProList?id=' + prop
      break
    case 'pop':
      appLink = 'mall_shopHomepage?id=' + prop
      break
    case 'category':
      appLink = 'mall_categoryProList?id=' + prop
      break
    case 'shopping_cart':
      appLink = 'mall_cart'
      break
    case 'system_setting':
      appLink = 'system_setting'
      break
    case 'mall_homepage':
      appLink = 'mall_homepage?category=' + prop
      break
    case 'appH5':
      appLink = prop
      break
  }
  if ((env.isModianIos() || env.isModianAndroid()) && appLink) {
    if (type !== 'appH5') {
      appLink = 'md://' + appLink
    }
    if (env.isModianIos()) {
    // eslint-disable-next-line
      webkit.messageHandlers.jumpToAppPage.postMessage(appLink)
    } else {
    // eslint-disable-next-line
      appInterface.jumpToAppPage(appLink)
    }
  } else if (type === 'appH5') {
    location.href = prop
  } else {
    location.href = h5Link
  }
}

lowerdev.debounce = (func, immediate, wait = 1000) => {
  let timer
  return function () {
    const context = this
    const args = arguments
    if (timer) clearTimeout(timer)
    if (immediate) {
      timer = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    } else {
      const status = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (status) func.apply(context, args)
    }
  }
}

export default lowerdev
