import { get, post } from '@/utils/apiBase.js'

// 推广服务列表
const getPromotionList = option => {
    return get('/apis/project/cp/promotion_list', option)
}

// 推广服务信息配置-获取配置
const getPromotionDetail = option => {
    return get('/apis/project/cp/promotion_detail', option)
}

// 推广服务兑换-确定兑换
const sendPromotion = option => {
    return post('/apis/project/cp/promotion', option)
}



// 用户推广服务列表
const getUserPromotionList = option => {
    return get('/apis/project/cp/user_promotion_record_list', option)
}

// 用户推广服务详情
const getUserPromotionDetail = option => {
    return get('/apis/project/cp/user_promotion_record_detail', option)
}

// 取消预约推广服务
const cancelUserPromotion = option => {
    return post('/apis/project/cp/cancel_promotion', option)
}

const getUpdateTaskInfo = option => {
    return get('/apis/project/cp/pro_update_task_type', option)
}


export default {
    getPromotionList,
    getPromotionDetail,
    sendPromotion,
    getUserPromotionList,
    getUserPromotionDetail,
    cancelUserPromotion,
    getUpdateTaskInfo
}