import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import { removeQueryParam, getQueryParamValue } from '@/utils/tools.js'
import '@/service/flexible.min.js'
import '@vant/touch-emulator'

import '../public/fepro/iconfont/iconfont.css'
import '../public/fepro/iconfont/iconfont.js'
import '@/assets/styles/main.less'
import '@/assets/styles/render.less'
import '@/assets/styles/reset.less'
import '@/assets/styles/common.less'
import '@/assets/styles/vantReset.less'

import 'md-base-tools/editor/index.css'

import '@/service/sensors'

import imgUrlFilter from '../node_modules/md-base-tools/imgUrlFilter'
import graft from '../node_modules/md-base-tools/graft'
import updateApiClientHeader from 'md-base-tools/apiClient/updateApiClientHeader'
import '../node_modules/md-base-tools/fontSizeReset.js'
import { addImpression, addPositionClick, addFastSellClick } from '@/utils/sensors-inject'

import {
  Toast,
  ImagePreview,
  Picker,
  Popup,
  Loading,
  Field,
  Cell,
  CellGroup,
  Swipe,
  SwipeItem,
  Slider,
  Sticky,
  Dialog,
  ConfigProvider,
  Button,
  Progress,
  PullRefresh,
  List,
  Empty,
  Search,
  Image,
  Lazyload,
  Tab,
  Tabs,
  ActionSheet,
  Overlay,
  PickerGroup,
  DatePicker,
  TimePicker,
  Calendar,
  Uploader
} from "vant";

import "@babel/polyfill";
import Es6Promise from "es6-promise";

// import vConsole from 'vconsole'
// new vConsole();

require("es6-promise").polyfill();
Es6Promise.polyfill();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(graft)
  .use(ConfigProvider)
  .use(Picker)
  .use(Popup)
  .use(Field)
  .use(CellGroup)
  .use(Loading)
  .use(ImagePreview)
  .use(Swipe)
  .use(SwipeItem)
  .use(Slider)
  .use(Sticky)
  .use(Dialog)
  .use(Button)
  .use(Progress)
  .use(PullRefresh)
  .use(List)
  .use(Empty)
  .use(Search)
  .use(Image)
  .use(Lazyload)
  .use(Cell)
  .use(Tab)
  .use(Tabs)
  .use(ActionSheet)
  .use(Overlay)
  .use(PickerGroup)
  .use(DatePicker)
  .use(TimePicker)
  .use(Uploader)
  .use(Calendar);

app.config.globalProperties.$imgUrlFilter = imgUrlFilter
app.config.globalProperties.$updateApiClientHeader = updateApiClientHeader
app.config.globalProperties.$addImpression = addImpression
app.config.globalProperties.$addPositionClick = addPositionClick
app.config.globalProperties.$addFastSellClick = addFastSellClick

var sensors = require('sa-sdk-javascript')
app.config.globalProperties.sensors = sensors

var ifFirst = true
import { interceptLink } from "@/service/router"

store.dispatch('getUserInfo')

router.beforeEach(async (from) => {
    // 登录相关url屏蔽后跳转
    if(window.location.href.includes('loginToken')){
        let loginToken = getQueryParamValue(window.location.href,'loginToken');
        if (!Cookies.get('MDUSERTOKEN')) {
            Cookies.set("MDUSERTOKEN", loginToken)
        }
        window.location.href = removeQueryParam(window.location.href, 'loginToken');
    }
    await interceptLink(from.fullPath)
    // 存储utm参数
    const query = from.query
    if (ifFirst && query) {
        const arr = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content']
        for(let key of arr) {
            if (query[key]) {
                sessionStorage.setItem(key, query[key])
            }
        }
        ifFirst = false
    }
    window.scrollTo(0, 0);
    // if (!store.state.user.userId) {
    //     await store.dispatch('getUserInfo')
    // } else {
    //     store.commit('currentUser')
    // }
})

app.mount('#app')
