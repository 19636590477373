import Layout from '@/layout/default'
const identityRouter = {
  // 认证
  path: '/identity',
  name: 'identity',
  component: Layout,
  children: [
    {
      path: 'authResult/:id',
      name: 'authResult',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/identity/authResult/_id.vue')
    },
    {
      path: 'materialResult/:id',
      name: 'materialResult',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/identity/materialResult/_id.vue')
    },
    {
      path: 'verify', // 输入身份证信息
      name: 'verify',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/identity/verify/index.vue')
    }
  ]
}

export default identityRouter
