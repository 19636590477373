import RouterView from '@@/compose/routerView'

const protocalRouter = {
  path: "/protocal",
  name: "protocal",
  component: RouterView,
  children: [
    {
      path: ":fater_id/:son_id",
      name: "protocal",
      component: () => import("@/views/protocal/_id.vue"),
    },
    {
      path: "publish",
      name: "protocal_publish",
      component: () => import("@/views/protocal/publish.vue"),
    },
    {
      path: "export",
      name: "protocal_export",
      component: () => import("@/views/protocal/export.vue")
    }
  ],
};

export default protocalRouter
