import { getToken } from '@/utils/user'
import { apiClient } from 'md-base-tools/apiClient'
// import MDOSS from "./ossUpload"
import MDOSS from "md-base-tools/ossUpload"
import { mdToast } from './tools';

let headers = {
  user_id: getToken() ? getToken().split('#')[0] : '',
  userid: getToken() ? getToken().split('#')[0] : '',
  token: getToken() ? getToken().split('#')[1] : ''
}

export const updateHeaders = () => {
  headers.user_id = getToken() ? getToken().split('#')[0] : ''
  headers.userid = getToken() ? getToken().split('#')[0] : ''
  headers.token = getToken() ? getToken().split('#')[1] : ''
}

const needLogin = false
const env = process.env.VUE_APP_ENV // 环境

const apis = apiClient({
  env,
  headers,
  needLogin
})

export const post = (url, option, new_header) => {
  return apis({
    method: 'post',
    url: url,
    props: {
      json_type: 1,
      ...option
    },
    header: {
      ...headers,
      ...new_header
    }
  })
}
export const get = (url, option, new_header) => {
  return apis({
    method: 'get',
    url: url,
    props: {
      json_type: 1,
      ...option
    },
    header: {
      ...headers,
      ...new_header
    }
  })
}
export const postOrder = async (url, option) => {
  const data = await apis({
    method: 'post',
    url: url,
    props: option,
    header: headers
  })
  if (data.status !== 0) {
    mdToast(data.message)
  } else {
    return data
  }
}
export const oss = ({url,method, props}) => {
  return apis({
    method: method,
    url: url,
    props: {
      json_type: 1,
      ...props
    },
    header: {
      ...headers
    }
  })
}
export const OSSUpload = MDOSS( oss, getToken() ? getToken().split('#')[0] : '')
export default apis
