import { get, post } from '@/utils/apiBase.js';

// 协议-获取协议内容-通过father_id和son_id
const getprotocalFaterSonDetail = (option) => {
  return get('/apis/admin/bottom/fater_son/detail', option);
};

// 下载用户隐私清单
const sendDownloadUserInfoRequest = (option) => {
  return post('/apis/user/down_user_info', option)
}

export default {
  getprotocalFaterSonDetail,
  sendDownloadUserInfoRequest
};
