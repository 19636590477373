import { getToken, getIcon } from '@/utils/user'
import qs from 'qs'
import { updateHeaders } from '@/utils/apiBase'
import Cookies from 'js-cookie'
import env from "md-base-tools/env";

const state = () => ({
  userIcon: getIcon() ? getIcon() : '', // 头像
  userId: getToken() ? getToken().split('#')[0] : '',
  token: getToken() ? getToken().split('#')[1] : '',
  ifLogin: !!getToken(), // 是否登录
  linkUrl: '', // 下载链接
  platform: '', // 当前所处平台
})

const mutations = {
  currentlLinkUrl (state, update) {
    state.linkUrl = update
  },
  currentPlatform (state, update) {
    state.platform = update
  },
  currentUser(state) {
    state.userIcon = getIcon() ? getIcon() : '' // 头像
    state.userId = getToken() ? getToken().split('#')[0] : ''
    state.token = getToken() ? getToken().split('#')[1] : ''
    state.ifLogin = !!getToken() // 是否登录
    updateHeaders()
  }
}

const actions = {
  async currentlLinkUrlAction ({ commit }, update) {
    if (window.linkedme) {
      await init()
    } else {
      var timer = +new Date()
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.id = 'c' + timer
      script.src = '//static.lkme.cc/linkedme.min.js'
      document.querySelector('head').appendChild(script)
      await new Promise(resolve => {
        script.onload = async function () {
          await init()
          resolve()
        }
      })
    }
    /* eslint-disable */
    async function init () {
        linkedme.init('517f9e8ea35437bf8491f59c3d9da71b', { type: 'live' }, null)
        const data = {}
        data.type = 'live' // 表示现在使用线上模式,如果填写"test", 表示测试模式.【可选】
        // data.feature = '功能名称' // 自定义深度链接功能，多个名称用逗号分隔，【可选】
        // data.stage = '阶段名称' // 自定义深度链接阶段，多个名称用逗号分隔，【可选】
        data.channel = 'WEB' // 自定义深度链接渠道，多个名称用逗号分隔，【可选】
        // data.tags = '标签名称' // 自定义深度链接标签，多个名称用逗号分隔，【可选】
        data.ios_custom_url = 'https://m.modian.com/download' // 自定义iOS平台下App的下载地址，如果是AppStore的下载地址可以不用填写，【可选】
        data.ios_direct_open = 'true' // 未安装情况下，设置为true为直接打开ios_custom_url，默认为false【可选】
        data.android_custom_url = 'https://m.modian.com/download' // 自定义安卓平台下App的下载地址，【可选】
        data.android_direct_open = '' // 设置为true，所有情况下跳转android_custom_url，默认为false【可选】
        // 下面是自定义深度链接参数，用户点击深度链接打开app之后，params的参数会通过LinkedME服务器透传给app，由app根据参数进行相关跳转
        // 例如：详情页面的参数，写入到params中，这样在唤起app并获取参数后app根据参数跳转到详情页面
        data.params = JSON.stringify(update)
        await new Promise((resolve) => {
          linkedme.link(
            data,
            (err, response) => {
              commit('currentlLinkUrl', response.url)
              resolve(true)
            },
            false
          )
        })
        
    }
  },
  // 获取用户信息
  getUserInfo({ commit }) {
    return new Promise((resolve) => {
      graft.app({
        functionName: "onGetUserInfo",
        functionParams: {},
        callBack: e => {
          var token = ''
          var user_id = ''
          if (e.errorCode == 0) {
            user_id = e.data.user_id ? e.data.user_id : e.data.userId
            token = e.data.token
            let icon = e.data.icon || ""
            Cookies.set("MDUSERTOKEN", user_id + "#" + token)
            Cookies.set("MDUSERINFO", icon)
          } else {
            Cookies.remove("MDUSERTOKEN")
            Cookies.remove("MDUSERINFO")
          }
          commit('currentUser')
          resolve(true)
        },
        webFun: e => {
          if (env.isWxApp()) {
            const query = qs.parse(location.search, { ignoreQueryPrefix: true })
            if (query['MDUSERTOKEN'] && query['MDUSERTOKEN'].length > 3) {
              Cookies.set("MDUSERTOKEN_Mina", query['MDUSERTOKEN'])
            } else {
              if (query.miniProgram) {
                Cookies.remove("MDUSERTOKEN_Mina")
              }
            }
          } else {
            // Cookies.remove("MDUSERTOKEN")
            // Cookies.remove("MDUSERINFO")
          }
          
          commit('currentUser')
          resolve(true)
        }
      })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
