// import Layout from '@/layout'
import Layout from '@/layout/default'
import RouterView from '@@/compose/routerView'


const projectRouter = {
  // 项目
  path: "/project",
  name: "project",
  component: Layout,
  children: [
    {
      path: "create_order", // 下单页
      name: "project-creat_order",
      component: () => import("@/views/pay/create_order.vue"),
    },
    {
      path: "client/detail/:id", // app内嵌-项目详情
      name: "project-client-detail",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/project/client/detail/_id.vue"),
    },
    {
      path: "update", // 更新相关
      name: "update",
      component: RouterView,
      children: [
        {
          path: "list", // 更新列表
          name: "project-update-list",
          component: () => import("@/views/project/update/list.vue"),
        },
        {
          path: "detail/:id", // 更新详情
          name: "project-update-detail",
          component: () => import("@/views/project/update/detail/_id.vue"),
        },
        {
          path: "client/detail/:id", // app内嵌-更新详情
          name: "project-update-client-detail",
          meta: {
            isShowHeader: true,
            isShowNav: true,
          },
          component: () =>
            import("@/views/project/update/client/detail/_id.vue"),
        },
        {
          path: "prize_rule", //抽奖规则汇总
          name: "project-update-prize-rule",
          meta: {
            title: "抽奖规则说明",
            isShowHeader: true,
            isShowNav: true,
          },
          component: () => import("@/views/project/update/prize_rule.vue"),
        },
        {
          path: "red_note", // 小红书种草活动-提交凭证
          name: "project-update-red_note",
          component: () => import("@/views/project/update/red_note.vue"),
        },
        {
          path: "red_note_prize", // 小红书种草活动-中奖信息
          name: "project-update-red_note_prize",
          meta: {
            title: "中奖名单",
            isShowHeader: true,
            isShowNav: true,
          },
          component: () => import("@/views/project/update/red_note_prize.vue"),
        },
      ],
    },
    {
      path: "common/video", // 视频详情
      name: "video",
      component: () => import("@/views/digital/detail/video.vue"),
    },
    {
      path: ":id", // 众筹详情
      name: "project-detail",
      meta: {
        isShowNav: true,
        isShowBtn: true,
      },
      component: () => import("@/views/project/_id.vue"),
    },
    {
      path: "list", // 全部项目列表
      name: "project_list",
      component: () => import("@/views/rank/project_list.vue"),
    },
    {
      path: "rank", // 榜单
      name: "ranking_list",
      component: () => import("@/views/rank/ranking_list.vue"),
    },
    {
      path: 'invite',
      name: '团队成员邀请',
      meta: {
        isShowNav: true
      },
      component: () => import('@/views/invite/index.vue')
    }
  ],
};

export default projectRouter
