import Layout from '@/layout/default';
const orderRouter = {
  // 活动
  path: '/after_sale',
  name: 'after_sale',
  component: Layout,
  children: [
    {
      path: 'detail',
      name: '售后详情',
      meta: {
        title: '售后详情',
        isShowHeader: false,
        isShowNav: true,
      },
      component: () => import('@/views/after_sale/detail.vue')
    },
    {
      path: 'apply/:id',
      name: '申请售后',
      meta: {
        title: '申请售后',
        isShowHeader: false,
        isShowNav: false,
      },
      component: () => import('@/views/after_sale/apply.vue'),
    },
    {
      path: 'appeal/:id',
      name: '申诉',
      meta: {
        title: '申诉',
        isShowHeader: false,
        isShowNav: false,
      },
      component: () => import('@/views/after_sale/appeal.vue'),
    },
  ],
};

export default orderRouter;
