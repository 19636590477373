import { get, post } from '@/utils/apiBase.js'
// 订单title
const getOrderTitleList = option => {
  return get('/apis/mdorder/order_title_list', option)
}
const getOrderList = option => {
  // return get('/apis/mdorder/order_list', option)
  return get('/apis/mdorder/order_list_v2', option)
}
const getOrderDetail = option => {
  return get('/apis/mdorder/order_detail_v2', option)
  // return get('/apis/mdorder/order_detail', option)
  
}
const getMallOrderDetail = option => {
  return get('/apis/mall/order/detail_v2', option)
}
const getScheme = option => {
  return get('/apis/weixin_api/get_scheme', option)
}
const MallPreviewOrder = option => {
  return post('/mall/preview/order', option)
}
// 创建订单，获取pay_id --电商
const CreateOrder = option => {
  return post('/mall/order', option)
}

// 创建订单，获取pay_id --众筹
const CreateOrderPro = (option) => {
  return post('/main/add_back', option, {
    apiShow: false
  })
}

const OrderSendPay = option => {
  return post('/mall/order/sendpay', option)
}
// 获取payId
const OrderResetPay = option => {
  return post('/mall/order/resetpay', option)
}
// 获取支付成功信息
const payPay_success_api = option => {
  return get('/pay/pay_success_api', option)
}

//  微信点金计划商家小票页面获取支付成功信息
const goldPaySuccessInfo = option => {
  return get('/apis/mdpay/pay_info', option)
}

export default {
  getOrderTitleList,
  getOrderList,
  getOrderDetail,
  getScheme,
  getMallOrderDetail,
  MallPreviewOrder,
  CreateOrder,
  CreateOrderPro,
  OrderSendPay,
  OrderResetPay,
  payPay_success_api,
  goldPaySuccessInfo
}
