import { get } from '@/utils/apiBase.js'
// banner
const homeRecommend = option => {
    return get('/hotspot/new_home_recommend', option)
}
// feed
const feedList = option => {
    return get('/apis/recommend/feed_list_v2', option)
}

const getIpAddressInfo = option => {
    return get('/apis/ip_address/get_ip_address_info', option)
}
export default {
    homeRecommend,
    feedList,
    getIpAddressInfo
}
