import Layout from '@/layout/default'
const HomeRouter = {
    path: '/rank',
    name: 'ranking_list',
    component: Layout,
    children: [
        {
            path: '',
            name: 'ranking_list',
            component: () => import('@/views/rank/ranking_list.vue')
        },
        {
            path: 'fastsell/category',
            name: 'rank-category',
            meta: {
              title: '摩点排行榜',
              isShowHeader: true,
              isShowNav: true
            },
            component: () => import('@/views/rank/category.vue'),
        }
    ]
}

export default HomeRouter
