import { post, get } from "@/utils/apiBase.js";
// 获取视频播放链接
const showVideoList = (option) => {
  return post("/apis/upload/mdmedia/show_video_list", option);
};
// 获取地址列表
const getAddressList = (option) => {
  return get("/apis/address/get_address_data", option);
};
// 获取区号
const getCountryCode = (option) => {
  return get("/account/country_code", option);
};

// 获取协议数据
const getProductAgreement = (option) => {
  return get("/apis/product/get_agreement", option);
};

// 获取微信小程序scheme url
const getWxScheme = (option) => {
  return get("/apis/weixin_api/get_scheme", option);
};
// 获取分享详情
const getShareInfo = option => {
  return post('/v45/main/share_info', option)
}
// 获取物流公司列表
const getExpressCompanyList = (option) => {
  return get("/mall/logistics_tools/express_company_list", option);
};

// 提交众筹意向
const sendProjectIntention = (option) => {
  return post("/apis/project/pro_intention", option);
};

// 获取物流详情
const getMdlogisticsInfos = (option) => {
  return get("/apis/mdlogistics/infos", option);
};

export default {
  showVideoList,
  getAddressList,
  getProductAgreement,
  getCountryCode,
  getWxScheme,
  getExpressCompanyList,
  sendProjectIntention,
  getMdlogisticsInfos,
  getShareInfo
};
