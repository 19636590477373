// import Layout from '@/layout'
import Layout from "@/layout/default";

const intentionRouter = {
  // 众筹意向
  path: "/project_intention",
  name: "intention",
  component: Layout,
  children: [
    {
      path: "detail", // 详情
      name: "intention-detail",
      meta: {
        title: "众筹意向收集",
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/project/intention/detail.vue"),
    },
  ],
};

export default intentionRouter;
