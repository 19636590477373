import { env } from "md-base-tools/env";

export const cdInit = function (time) {
  const cdTime = time
  const day = Math.floor(cdTime / (24 * 60 * 60))
  let hour = Math.floor((cdTime % (24 * 60 * 60)) / (60 * 60))
  let minute = Math.floor(((cdTime % (24 * 60 * 60)) % (60 * 60)) / 60)
  let sec = Math.floor(((cdTime % (24 * 60 * 60)) % (60 * 60)) % 60)
  hour = hour > 9 ? hour : '0' + hour
  minute = minute > 9 ? minute : '0' + minute
  sec = sec > 9 ? sec : '0' + sec
  this.timeText = `${day}天${hour}时${minute}分${sec}秒`
}
export const fmTime = function (time) {
  const cdTime = time
  const day = Math.floor(cdTime / (24 * 60 * 60))
  let hour = Math.floor((cdTime % (24 * 60 * 60)) / (60 * 60))
  let minute = Math.floor(((cdTime % (24 * 60 * 60)) % (60 * 60)) / 60)
  let sec = Math.floor(((cdTime % (24 * 60 * 60)) % (60 * 60)) % 60)
  hour = hour > 9 ? hour : '0' + hour
  minute = minute > 9 ? minute : '0' + minute
  sec = sec > 9 ? sec : '0' + sec
  if (time > 24 * 60 * 60) {
    return [sec, minute, hour, day]
  } else if (time > 60 * 60) {
    return [sec, minute, hour]
  } else if (time > 60) {
    return [sec, minute]
  } else {
    return [sec]
  }
}

export const fmoney = function (s, n) {
  let m = ''
  if (String(s).indexOf('-') !== -1) {
    m = '-'
    s = -Number(s)
  }
  n = String(s).indexOf('.') !== -1 ? 2 : (n || 0)
  s = String(parseFloat(String(s).replace(/[^\d\\.-]/g, '')).toFixed(n))
  var l = s.split('.')[0].split('').reverse()
  var r = s.indexOf('.') !== -1 ? '.' + s.split('.')[1] : ''
  var t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
  }
  return (m + t.split('').reverse().join('') + r)
}

export const throttle = function(fn, time) {
  let timer = null
  time = time || 1000
  return function(...args) {
    if (timer) {
      return
    }
    const _this = this
    timer = setTimeout(() => {
      timer = null
    }, time)
    fn.apply(_this, args)
  }
}

export const getListOne = function (list) {
  if (typeof list == 'object') {
      const arr = Object.keys(list)
      if (arr.length) return arr[0]
  }
  return 'OD'
}

// 计算字符串长度
export const computeTextLength = function(data) {
  const _data = (data || '').toString().replace(/\s+/g, ' ')
  let length = 0
  Array.from(_data).map((char) => {
    if (char.charCodeAt(0) > 255) {
      length += 1
    } else {
      length += 0.5
    }
  })
  return length
}

export const GoBack = function(){
  if (!document.referrer && !(window.history.state && window.history.state.back)) {
    /* eslint-disable */
    graft.app({
        functionName: "closeController",
        webFun: e => {
            window.history.go(-1)
        }
    })
  } else {
      window.history.go(-1)
  }
}

export const postWXMessage = function(data) {
  if (env.isWxApp()) {
    // eslint-disable-next-line no-undef
    wx.miniProgram.postMessage({ 
        data
    })
  }
}

/* eslint-disable */
// 将url转换为{key: value, key: value}
export const urlToJson = function (str) {
  let obj = {}
  if (str.length) {
    let parr = str.split('&');
    for(let i of parr) { 
        let arr = i.split('=');
        obj[arr[0]] = arr[1];
    }
  }
  return obj
}

export const numFitWanFormat = function (num, length = 2) {
  return Number(num) < 10000 ? num : (num / 10000).toFixed(length) + '万'
}

export const decodeEntities = (encodedString) => {
  var divElement = document.createElement("div");
  divElement.innerHTML = encodedString;
  return divElement.innerText;
}

// 复制内容
import ClipboardJS from "clipboard";
import { ImagePreview, showToast, showConfirmDialog, showDialog, showSuccessToast, showFailToast } from 'vant';

export const QuickCopy = function (text, message) {

  if (navigator.clipboard && navigator.permissions) {

    mdToast(message || '复制成功')

    navigator.clipboard.writeText(text)

  } else {

    const textArea = document.createElement('textArea')
    textArea.value = text
    textArea.style.width = 0
    textArea.style.position = 'fixed'
    textArea.style.left = '-999px'
    textArea.style.top = '10px'
    textArea.setAttribute('readonly', 'readonly')
    document.body.appendChild(textArea)
    textArea.focus();
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)

    mdToast(message || '复制成功')

  }


}

export const CopyContent = function(e, text) {
  const clipboard = new ClipboardJS(e.target, {
      text(target) {
      console.log('target------>', target)
      //console.log('target', target.getAttribute("copyData"))
        return text
      }
  })
  clipboard.on('success', () => {
    mdToast('复制成功')
      // 释放内存
      clipboard.off('error')
      clipboard.off('success')
      clipboard.destroy()
  })
  clipboard.on('error', () => {
      // 不支持复制
    mdToast('该浏览器不支持自动复制')
      // 释放内存
      clipboard.off('error')
      clipboard.off('success')
      clipboard.destroy()
  })
  clipboard.onClick(e)
}


import { toRaw } from 'vue'
// 查看大图
export const PreviewImages = function(index, imgs) {
  graft.app({
      functionName: "onImageClicked",
      functionParams: {
          imageUrls: toRaw(imgs),
          imageIndex: index
      },
      webFun: e => {
          ImagePreview({
              images: imgs,
              startPosition: index
          });
      }
  })
}

export const mdToast = function(message) {
  // eslint-disable-next-line
  graft.app({
      functionName: "showToast",
      functionParams: message,
      callBack: e => {
        if (e.errorCode != 0) {
          showToast({
            message: message
          });
        }
      },
      webFun: () => {
        showToast({
          message: message
        });
      }
  })
}
export const mdAlert = function ({ title, content, actions = ['取消', '确定'], _callback }) {

  console.log('action-----', actions)

  // eslint-disable-next-line
  graft.app({
      functionName: "showAlert",
      functionParams: {
        title,
        content,
        actions
      },
      callBack: e => {
        if (e.errorCode == 0) {
          if (actions.length == 2) {
            if (e.data.action_index == 1) {
              _callback()
            }
          } else {
            if (e.data.action_index == 0) {
              _callback()
            }
          }

        } else {
          if (actions.length == 2) {
            showConfirmDialog({
              title: title,
              message: content,
              cancelButtonText: actions[0],
              confirmButtonText: actions[1],
              confirmButtonColor: "#00C4A1",
            }).then(() => {
              _callback()
            })
          } else {
            showDialog({
              title: title,
              message: content,
              confirmButtonText: actions[0],
              confirmButtonColor: "#00C4A1"
            }).then(() => {
              _callback()
            })
          }

        }
      },
      webFun: () => {
        if (actions.length == 2) {
          showConfirmDialog({
            title: title,
            message: content,
            cancelButtonText: actions[0],
            confirmButtonText: actions[1],
            confirmButtonColor: "#00C4A1",
          }).then(() => {
            _callback()
          })
        } else {
          showDialog({
            title: title,
            message: content,
            confirmButtonText: actions[0],
            confirmButtonColor: "#00C4A1"
          }).then(() => {
            _callback()
          })
        }
      }
  })
}
export const removeQueryParam = function(url, key) {
  let urlParts = url.split('?');
  if (urlParts.length >= 2) {
      let queryParams = urlParts[1].split('&').filter(param => {
          return param.split('=')[0] !== key;
      });
      return urlParts[0] + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');
  }
  return url;
}
export const getQueryParamValue = function(url, key) {
  let queryParams = url.split('?')[1].split('&');
  for (let i = 0; i < queryParams.length; i++) {
      let param = queryParams[i].split('=');
      if (param[0] === key) {
          return decodeURIComponent(param[1]);
      }
  }
  return null;
}
