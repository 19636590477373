import { domClientWidth } from 'md-base-tools/env'

const layerdev = {
  active: null,
  holdon: null,
  mask_keep: null,
  mask_hold: null,
  showHoldon (ele) {
    this.mask_hold.style.display = 'block'
    ele.style.display = 'block'
    this.holdon = ele
  },
  closeHoldon () {
    if (this.holdon) {
      this.holdon.style.display = 'none'
      this.holdon.parentNode.removeChild(this.holdon)
      this.holdon = null
    }
    this.mask_hold.style.display = 'none'
  },
  showPopup (ele) {
    document.body.style.overflowY = 'hidden'
    this.mask_keep.style.display = 'block'
    ele.style.display = 'block'
    this.active = ele
  },
  closePopup () {
    if (this.active) {
      this.active.style.display = 'none'
      this.active.parentNode.removeChild(this.active)
      this.active = null
    }
    this.mask_keep.style.display = 'none'
    document.body.style.overflowY = 'auto'
  },
  init (el, customFun, cancelBack, callBack) {
    let popupEle = null
    let popupLock = null
    let popupType = null
    const popupBox = document.createElement('div')
    popupBox.className = 'popup-box-body'
    if (typeof el === 'object') {
      if (Array.isArray(el)) {
        throw new Error('layerdev.init: props(el) must be an array')
      }
      popupEle = el.type
      popupLock = true
      const bodyBd = document.createElement('div')
      const bodyFt = document.createElement('div')
      const textPrimary = document.createElement('span')
      if (popupEle === 'holdon') {
        let strHtml = `<div class="popup-msg-holdon"><img src="${require('@assets/images/animation/popup_loading.gif')}">`
        if (el.msg) {
          strHtml += '<span>' + el.msg + '</span>'
        }
        strHtml += '</div>'
        popupBox.innerHTML = strHtml
      } else {
        bodyBd.className = 'popup-body-bd'
        let strHtml = ''
        if (el.title) {
          strHtml += '<strong>' + el.title + '</strong><p>'
        } else {
          strHtml += '<p style="text-align:center">'
        }
        strHtml += el.msg + '</p>'
        bodyBd.innerHTML = strHtml
        bodyFt.className = 'popup-body-ft'
        textPrimary.className = 'text-primary'
        textPrimary.innerHTML = el.success
      }
      if (popupEle === 'toast') {
        if (el.popup) {
          const msgToast = document.createElement('div')
          msgToast.className = 'popup-msg-toast'
          bodyFt.appendChild(textPrimary)
          bodyBd.appendChild(bodyFt)
          msgToast.appendChild(bodyBd)
          popupBox.appendChild(msgToast)
          textPrimary.onclick = () => {
            this.closePopup()
            typeof cancelBack === 'function' && cancelBack()
          }
        } else {
          const obj = document.createElement('div')
          obj.className = 'toast'
          obj.innerHTML = el.msg
          document.body.appendChild(obj)
          setTimeout(() => {
            obj.parentNode.removeChild(obj)
            typeof cancelBack === 'function' && cancelBack()
          }, 2500)
          return false
        }
      }
      if (popupEle === 'confirm') {
        const msgConfirm = document.createElement('div')
        const textCancel = document.createElement('span')
        msgConfirm.className = 'popup-msg-confirm'
        textCancel.className = 'text-cancel'
        textCancel.innerHTML = el.cancel
        bodyFt.appendChild(textCancel)
        bodyFt.appendChild(textPrimary)
        bodyBd.appendChild(bodyFt)
        msgConfirm.appendChild(bodyBd)
        popupBox.appendChild(msgConfirm)
        textPrimary.onclick = () => {
          this.closePopup()
          typeof callBack === 'function' && callBack()
        }
        textCancel.onclick = () => {
          this.closePopup()
          typeof cancelBack === 'function' && cancelBack()
        }
      }
      if (popupEle === 'alert') {
        const msgConfirm = document.createElement('div')
        msgConfirm.className = 'popup-msg-confirm'
        bodyFt.appendChild(textPrimary)
        bodyBd.appendChild(bodyFt)
        msgConfirm.appendChild(bodyBd)
        popupBox.appendChild(msgConfirm)
        textPrimary.onclick = () => {
          this.closePopup()
          typeof callBack === 'function' && callBack()
        }
      }
    } else {
      const ele = document.getElementById(el)
      popupEle = ele.getAttribute('popupEle')
      popupType = ele.getAttribute('popupType')
      if (!ele.getAttribute('popupLock')) {
        popupLock = false
      }
      if (popupType === 'popup_div') {
        const cloneNode = document.querySelector('.' + popupEle).cloneNode(true)
        popupBox.appendChild(cloneNode)
        cloneNode.querySelectorAll('.modalFun').forEach((ele) => {
          ele.onclick = () => {
            const dataModal = ele.getAttribute('data-modal')
            typeof customFun[dataModal] === 'function' && customFun[dataModal]()
            if (['popup_submit', 'popup_dismiss'].includes(dataModal)) {
              this.closePopup()
            }
          }
        })
      }
      if (popupType === 'popupIframe') {
        const iframe = document.createElement('iframe')
        const popupIframe = ele.getAttribute('popupIframe')
        iframe.frameborder = 'no'
        iframe.name = popupType
        iframe.src = popupIframe
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        popupBox.appendChild(iframe)
      }
    }

    let popupStyle = ''
    let popupCloseStyle = ''
    let popupClassName = 'popup-box ' + popupEle + '-box'

    if (popupEle !== 'holdon') {
      if (domClientWidth() > 780) {
        if (popupType === 'popupIframe') {
          popupStyle = 'width:18rem;height:12rem;'
        } else {
          popupStyle = 'width:8.4rem;'
        }
        popupCloseStyle = 'top:.7rem;right:0;'
        popupClassName += ' model-xlarge'
      } else {
        if (popupType === 'popupIframe') {
          popupStyle = 'width:92%;height:80%;'
        } else {
          popupStyle = 'width:80%;'
        }
        popupCloseStyle = 'top:0;right:.7rem;'
      }
    }

    const popup = document.createElement('div')
    popup.className = popupClassName
    popup.style = popupStyle

    let popupClose = null
    if (!popupLock) {
      popupClose = document.createElement('div')
      popupClose.className = 'popup-close'
      popupClose.style = popupCloseStyle
      popup.appendChild(popupClose)
      popupClose.onclick = () => {
        this.closePopup()
      }
    }

    popup.appendChild(popupBox)
    document.body.appendChild(popup)

    if (popupEle === 'holdon') {
      if (!this.mask_hold) {
        this.mask_hold = document.createElement('div')
        this.mask_hold.className = 'back-mask-hold'
        document.body.appendChild(this.mask_hold)
      }
      this.showHoldon(popup)
    } else {
      if (!this.mask_keep) {
        this.mask_keep = document.createElement('div')
        this.mask_keep.className = 'back-mask-keep'
        document.body.appendChild(this.mask_keep)
      }
      if (popupLock) {
        this.mask_keep.onclick = null
      } else {
        this.mask_keep.onclick = () => {
          this.closePopup()
        }
      }
      this.showPopup(popup)
    }
  }
}

export default layerdev
