import Layout from '@/layout/default';
const reviewRouter = {
  // 活动
  path: '/review',
  name: 'review',
  component: Layout,
  children: [
    {
      path: 'detail',
      name: '审核详情',
      meta: {
        title: '审核详情',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/review/detail.vue')
    }
  ],
};

export default reviewRouter;
