import Layout from '@/layout/default.vue';
const luckycardsRouter = {
  // 摩点日报
  path: '/news/fastsell',
  name: 'news',
  component: Layout,
  children: [
    {
      path: 'daily',
      name: 'news-daily',
      meta: {
        title: '摩点日报',
        isShowHeader: true,
        isShowNav: true
      },
      component: () => import('@/views/news/daily.vue'),
    }
  ],
};

export default luckycardsRouter;
