import Layout from '@/layout/default'

const containerRouter = {
  path: '/luckycards',
  name: 'container',
  component: Layout,
  children: [{
      path: 'container',
      name: 'container',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/luckycards/container.vue')
    },
    {
      path: 'container_rule',
      name: 'container_rule',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/luckycards/container_rule.vue')
    },
    // {
      // path: 'container_detail',
      // name: 'container_detail',
      // meta: {
      //   isShowHeader: true,
      //   //isShowNav: true
      // },
      // component: () => import('@/views/luckycards/container_detail.vue')
    // },
    {
      path: 'container_order_list',
      name: 'container_order_list',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/luckycards/container_order_list.vue')
    },
    {
      path: 'container_order_detail',
      name: 'container_order_detail',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/luckycards/container_order_detail.vue')
    },
    {
      path: 'logistics_list',
      name: 'logistics_list',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/luckycards/logistics_list.vue')
    },
    {
      path: 'logistics_detail',
      name: 'logistics_detail',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/luckycards/logistics_detail.vue')
    },
  ]
}

export default containerRouter
