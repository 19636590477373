import { get, post } from '@/utils/apiBase.js';
// 获取活动藏品信息
const getActivityList = (option, headers) => {
  return get('/apis/digital/nft/list_by_activity', option, headers);
};
// 藏品合成
const cbaMerger = (option) => {
  return post('/apis/digital/nft/merger', option);
};
// 藏品合成-指定序列号
const cbaMergerByHash = (option) => {
  return post('/apis/digital/nft/merger/by_hash', option);
};
// 藏品已合成列表
const getMergerList = (option) => {
  return get('/apis/digital/nft/list_by_merger', option);
};
// 添加中奖收货地址
const addHashAddress = (option) => {
  return post('/apis/digital/nft/add_hash_address', option);
};
// 获取中奖收货地址
const getHashAddress = (option) => {
  return get('/apis/digital/nft/query_hash_address', option);
};

// 获取藏品模板数据
const getUIdata = (option) => {
  return get('/apis/digital/merge/data', option);
};

// 修改中奖收货地址
const updateHashAddress = (option) => {
  return post('/apis/digital/nft/update_hash_address', option);
};

// 十二生肖补救-羊马交换
const nfgExReceive = (option) => {
  return post('/apis/digital/nft/ex/receive', option);
};

// 获取兑换方式
const exchangeMode = (option) => {
  return get('/apis/card-wallet/activity/exchange/mode', option);
};

// 兑换记录
const exchangeRecord = (option) => {
  return get('/apis/card-wallet/activity/exchange/record', option);
};

// 可兑换列表
const convertibleList = (option) => {
  return get('/apis/card-wallet/activity/convertible/list', option);
};

// 活动兑换
const activityExchange = (option) => {
  return post('/apis/card-wallet/activity/exchange', option);
};

//获取akb48项目信息
const getAKB48Info = (option) => {
  return get('/mdcporder/order/get_akb48_info', option);
}

// 获取矿工财富榜
const getMinerRank = (option) => {
  return get('/apis/project/miner_score_rank', option);
};

// 获取历史挖矿积分日期
const getMinerRankDate = (option) => {
  return get('/apis/project/miner_rank_date', option);
};

// 获取挖矿任务列表
const getMinerTaskList = (option) => {
  return get('/apis/project/cp/get_miner_task_list', option);
};

// 领取挖矿任务积分
const receiveMinerTask = (option) => {
  return post('/apis/project/cp/receive_miner_score', option);
};

export default {
  exchangeMode,
  exchangeRecord,
  convertibleList,
  activityExchange,
  getActivityList,
  cbaMerger,
  cbaMergerByHash,
  getMergerList,
  addHashAddress,
  getHashAddress,
  getUIdata,
  updateHashAddress,
  nfgExReceive,
  getAKB48Info,
  getMinerRank,
  getMinerRankDate,
  getMinerTaskList,
  receiveMinerTask
};
