import { sensorsParams } from 'md-base-tools/env'

var sensors = require('sa-sdk-javascript')
sensors.init({
  server_url: `https://da.modian.com/sa?project=${process.env.NODE_ENV === 'production' ? 'production' : 'default'}`,
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon',
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'default'
  },
  use_app_track: false,
  cross_subdomain: true,
  is_track_device_id: true,
  show_log: true
})

const sensors_params = {
  md_client: 'wap',
  client: 3,
  ...sensorsParams
}

sensors.registerPage(sensors_params)

sensors.quick('autoTrack') // 用于采集 $pageview 事件。
