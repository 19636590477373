import { get, post } from '@/utils/apiBase.js'
// 卡包列表
const getCardList = option => {
  return get('/apis/temp_card/list', option)
}
// 支付，确认发货
const cardDeliverGoods = (option, headers) => {
  return post('/apis/card-wallet/deposit/deliver_goods', option, headers)
}
// 订单卡牌明细列表
const getCardDetail = option => {
  return get('/apis/mdorder/cards', option)
}

// 卡包列表第一层级
const getFirstLevelList = option => {
  return get('/apis/card-wallet/deposit/first_level/list', option)
}

// 卡包列表第二层级
const getSecondLevelList = option => {
  return get('/apis/card-wallet/deposit/second_level/list', option)
}

// 卡包列表第三层级-明细
const getThreeLevelList = option => {
  return get('/apis/card-wallet/deposit/three_level/list', option)
}

// 获取项目等级接口
const getLevelList = option => {
  return get('/apis/luckycards/extract_card/level', option)
}

// 发货单列表
const getGoodsList = option => {
  return get('/apis/card-wallet/deliver_goods/list', option)
}
 
// 发货单详情
const getGoodsDetail = option => {
  return get('/apis/card-wallet/deliver_goods/detail', option)
}
// 取消订单
const cancelOrder = (option) => {
  return post('/apis/card-wallet/deposit//apis/card-wallet/shipping_order/cancel', option)
}

// 重新支付
const sendPay = (option) => {
  return post('/apis/card-wallet/shipping_order/send_pay', option)
}

// 修改地址
const modifyAddress = (option) => {
  return post('/apis/card-wallet/modify/address', option)
}

// 删除订单
const delOrder = (option) => {
  return post('/apis/card-wallet/del/postage_order', option)
}

// 物流列表
const getLogisticsList = option => {
  return get('/apis/card-wallet/logistics/list', option)
}
// 获取运费模板详情
const getFreightTemplateInfo = option => {
  return get('/apis/mall/freight_template/freight_template_info', option)
}
export default {
    getCardList,
    cardDeliverGoods,
    getCardDetail,
    getFirstLevelList,
    getSecondLevelList,
    getThreeLevelList,
    getLevelList,
    getGoodsList,
    getGoodsDetail,
    cancelOrder,
    sendPay,
    modifyAddress,
    delOrder,
    getLogisticsList,
    getFreightTemplateInfo
}
