import Layout from '@/layout/default.vue';
const luckycardsRouter = {
  // 抽卡机
  path: '/luckycards/fastsell',
  name: 'luckycards',
  component: Layout,
  children: [
    {
      path: 'openhtml',
      name: 'luckycards-openhtml',
      meta: {
        title: '卡册图鉴',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/openhtml.vue'),
    },
    {
      path: 'collect',
      name: 'luckycards-collect',
      meta: {
        title: '卡册图鉴', // 自己的抽卡机列表
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/collect.vue'),
    },
    {
      path: 'prize',
      name: 'luckycards-prize',
      meta: {
        title: '拆包',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/prize.vue'),
    },
    {
      path: 'user_collect',
      name: 'luckycards-user-collect',
      meta: {
        title: '图鉴详情',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/user_collect.vue'),
    },
    {
      path: 'detail/:id',
      name: 'luckycards-info',
      meta: {
        title: '抽卡机详情',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/detail.vue'),
    },
    {
      path: 'list',
      name: 'luckycards-list',
      meta: {
        title: '抽卡机列表',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/list.vue'),
    },
    {
      path: 'collect_list/:id',
      name: 'collect-list',
      meta: {
        title: '抽卡机合集列表',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/luckycards/collect_list.vue'),
    }
  ],
};

export default luckycardsRouter;
