import { get, post } from '@/utils/apiBase.js';
// 个人中心详情
const userDetail = (option) => {
  return get('/v45/user/detail', option);
};
// 订单信息
const personCenter = (option) => {
  return get('/v45/user/person_center', option);
};
// 认证状态获取
const authCheck = (option) => {
  return get('/apis/user_auth_account/auth_info', option);
};
// 获取藏品数量
const query = (option) => {
  return get('/apis/digital/wallet/button/query', option);
};
// 底部双列推荐
const feed_list_v2 = (option) => {
  return get(
    '/apis/recommend/feed_list_v2?ad_position=person_page_recommen',
    option
  );
};
// 顶部个人信息&卡片
const userInfo = (option) => {
  return get('/apis/comm/user/user_info', option);
};
// 发的众筹列表
const buildProductList = (option) => {
  return get('/apis/user_center/build_product_list', option);
};
// 支持的项目
const backProductList = (option) => {
  return get('/apis/user_center/back_product_list', option);
};
// 看好创意
const bullProductList = (option) => {
  return get('/apis/user_center/bull_product_list', option);
};
// 关注的项目
const myFavorsProductList = (option) => {
  return get('/apis/user_center/my_favors_product_list', option);
};
// 关注的人
const followList = (option) => {
  return get('/apis/comm/user/follow_list', option);
};
// 关注
const follow = (option) => {
  return post('/apis/comm/user/follow', option);
};
// 查看钱包信息
const walletQuery = (option) => {
  return get('/apis/digital/wallet/query', option);
};
// 查看藏品
const nftList = (option) => {
  return get('/apis/digital/nft/list', option);
};
// 查看资质
const companyAuthorization = (option) => {
  return get('/mall/company_authorization', option);
};
// 查询可领取积分
const getCpCode = (option) => {
  return get('/wallet/apis/get/amount/by/code', option);
};


export default {
  query,
  authCheck,
  userDetail,
  personCenter,
  feed_list_v2,
  userInfo,
  buildProductList,
  backProductList,
  bullProductList,
  myFavorsProductList,
  followList,
  follow,
  walletQuery,
  nftList,
  companyAuthorization,
  getCpCode
};
