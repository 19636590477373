import Layout from '@/layout/default.vue';
const boardRouter = {
  // 跳板页
  path: '/board',
  name: 'board',
  component: Layout,
  children: [
    {
      path: '',
      name: 'board-index',
      meta: {
        title: '摩点 - 文化创意众筹社区',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/board/index.vue'),
    }
  ],
};

export default boardRouter;
