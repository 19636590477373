import { get } from '@/utils/apiBase.js'
// 请求顶部分类数据
const zcCategory = option => {
    return get('/v45/category/zc_category', option)
}

const mdProjectList = option => {
    return get('/apis/mdmain/md_project_list', option)
}
export default {
    zcCategory,
    mdProjectList
}
