import { get, post } from '@/utils/apiBase.js'
// 获取藏品详情
const getNtfDeatil = option => {
  return get('/apis/digital/nft/detail_ut', option)
}
const ntfReceive = option => {
  return post('/apis/digital/nft/receive', option)
}

export default {
    getNtfDeatil,
    ntfReceive
}