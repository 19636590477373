import { getWxScheme } from "@/api/index";

// 获取微信小程序scheme url
export const getSchemeUrl = async (option) => {

  const res = await getWxScheme(option)
  if (res && res.status== 0) {
      return res.data
  } else {
    return false
  }
}