import { get, post } from '@/utils/apiBase.js'
// 卡包列表
const getProjectDaily = option => {
  return get('/apis/project/project_daily', option)
}
// 定制化日报列表
const getExactProjectDaily = option => {
  return get('/apis/project/exact_project_daily', option)
}

// 日报已读
const setProjectDailyRead = option => {
  return post('/apis/push/message/set_daily_report_read',option)
}

export default {
    getProjectDaily,
    getExactProjectDaily,
    setProjectDailyRead
}
