import lowerdev from "@/service/lowerdev";
import header from "md-base-tools/header";
import env from "md-base-tools/env";
// import UserCenterSidebar from '@@/common/UserCenterSidebar'
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    // UserCenterSidebar
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    user_center_show() {
      return !(env.isModianIos() || env.isModianAndroid() || sessionStorage.getItem("miniProgram") || env.isWxApp()) && env.isMobile();
    },
    isShowHeader() {
      let isShowHeader = true;
      if (this.$route.meta && this.$route.meta.isShowHeader || !this.app_guide || env.isWeibo()) {
        isShowHeader = false;
      }
      console.log('---isShowHeader01----', isShowHeader);
      return isShowHeader;
    },
    isShowNav() {
      let isShowNav = true;
      if (this.$route.meta && this.$route.meta.isShowNav || env.isWxApp() || env.isModianIos() || env.isModianAndroid()) {
        isShowNav = false;
      }
      return isShowNav;
    },
    isShowBtn() {
      let isShowBtn = false;
      if (this.$route.meta && this.$route.meta.isShowBtn) {
        isShowBtn = true;
      }
      if (env.isWeibo() || !this.app_guide) {
        isShowBtn = false;
      }
      return isShowBtn;
    },
    metatitle() {
      if (this.$route.meta && this.$route.meta.title) {
        return this.$route.meta.title;
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      app_guide: this.$route.query.app_guide != "off" //是否打开APP引导下载，默认是
    };
  },
  watch: {
    metatitle(val) {
      // eslint-disable-next-line no-undef
      userHeader.titleText(val);
      document.title = val;
    },
    isShowNav(val) {
      // eslint-disable-next-line no-undef
      userHeader.backnavOnShow(val);
    },
    isShowHeader(val) {
      // eslint-disable-next-line no-undef
      userHeader.headerOnShow(val);
      console.log('---isShowHeader02----', val);
    },
    isShowBtn(val) {
      // eslint-disable-next-line no-undef
      userHeader.openappOnShow(val);
    },
    $route() {
      // eslint-disable-next-line no-undef
      // userHeader.getMdlink(location.href)
    }
  },
  beforeMount() {
    let userHeader = new header();
    userHeader.init({});
    userHeader.titleText(this.metatitle);
    document.title = this.metatitle;
    console.log('isShowHeader---', this.isShowHeader);
    userHeader.headerOnShow(this.isShowHeader);
    console.log('isShowNav---', this.isShowNav);
    userHeader.backnavOnShow(this.isShowNav);
    console.log('isShowBtn---', this.isShowBtn);
    userHeader.openappOnShow(this.isShowBtn);
    window.userHeader = userHeader;
    this.currentlLinkUrlAction();
    if (env.isModianIos() || env.isModianAndroid()) {
      this.currentPlatform("app");
    }
    if (env.isWeibo()) {
      this.currentPlatform("weibo");
      lowerdev.dynamicScript("https://tjs.sjs.sinajs.cn/open/thirdpart/js/jsapi/mobile.js");
    }
    if (env.isWxApp()) {
      this.currentPlatform("mina");
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
    if (env.isWeixin()) {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
    if (sessionStorage.getItem("miniProgram") == "qq") {
      lowerdev.dynamicScript("https://qqq.gtimg.cn/miniprogram/webview_jssdk/qqjssdk-1.0.0.js");
    }
  },
  mounted() {
    if (this.userId && this.userId !== this.sensors.store.getDistinctId()) {
      this.sensors.login(this.userId);
    }
  },
  beforeUnmount() {
    // eslint-disable-next-line no-undef
    // userHeader.backnavOnShow(true);
  },
  methods: {
    setShare() {
      // // #ifdef H5
      // let url = location.href.split("?")[0] + `?${this.$store.state.state.utm}`;
      // // #endif
      // const share_option = {
      //   url,
      //   id: '',
      //   title: "快来和我一起玩吧!",
      //   img: activity_img,
      //   wx_title: `欧气大比拼,快来和我一起玩摩立赏吧~`,
      //   wx_des: `${activity_name}`,
      //   weixin_share_url: url,
      //   wxTimeline_title: `快来和我一起玩吧!${activity_name}`,
      //   wxTimeline_des: `${activity_name}`,
      //   wxTimeline_share_url: url,
      //   qq_title: `快来和我一起玩吧!`,
      //   qq_des: `${activity_name}`,
      //   qq_share_url: url,
      //   qzone_title: `快来和我一起玩吧!`,
      //   qzone_des: `${activity_name}`,
      //   qzone_share_url: url,
      //   weibo_title: "",
      //   weibo_des: `快来和我一起玩吧!${activity_name}，${url}`,
      //   weibo_share_url: url,
      //   msg_des: `快来和我一起玩吧!${activity_name}，${url}`,
      //   sharelink_url: url,
      //   mini_programs_title: "快来和我一起玩吧!",
      //   mina_share_img: activity_img,
      //   mina_app_name: "gh_5d8f1c9a34cf",
      //   mina_share_path: url.split("com/")[1],
      // };
      // userHeader.setShareInfo(share_option)
    },
    ...mapMutations(["currentPlatform"]),
    ...mapActions(["currentlLinkUrlAction"])
  }
};