// Toast
import "vant/es/toast/style";

// Dialog
import "vant/es/dialog/style";

// Notify
import "vant/es/notify/style";

// ImagePreview
import "vant/es/image-preview/style";
import { showConfirmDialog } from "vant";
export default {
  created() {
    document.addEventListener("visibilitychange", () => this.checkPay());
  },
  methods: {
    checkPay() {
      // 用户息屏、或者切到后台运行 （离开页面）
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      }
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        console.log("页面可见");
        setTimeout(() => {
          const pay_id = sessionStorage.getItem("PayId");
          const fail_next = sessionStorage.getItem("FailNext") || "href";
          if (pay_id) {
            showConfirmDialog({
              title: "已完成支付?",
              cancelButtonText: "未支付",
              confirmButtonText: "支付完成",
              confirmButtonColor: "#00cba6"
            }).then(() => {
              // 确认-跳转支付结果页-下单页跳转的直接replace，订单列表/详情跳转的二跳

              if (fail_next == "href") {
                window.location.replace(`/order/payment/success?pay_id=${pay_id}`);
              } else {
                window.location.href = `/order/payment/success?pay_id=${pay_id}`;
              }
            }).catch(() => {
              // 取消

              sessionStorage.removeItem("FailNext");
              sessionStorage.removeItem("PayId");
              if (fail_next == "href") {
                //跳转待支付列表
                window.location.replace(`/order/order_list`);
              } else {
                //刷新本页面
                window.location.reload();
              }

              // on cancel
              throw new Error();
            });
          }
        }, 500);
      }
    }
  }
};