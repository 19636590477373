// import { getTopicInfo } from '@api/index'

const state = () => ({
  topic_id: '',
  topic_info: {}, // 话题详情
  post_id: '',
  feed_detail: {}, // 帖子详情
  topicIndex: 2, // 话题详情页tab默认选中热门
  collection_id: '',
  collection_info: {}// 合集详情
})

const mutations = {
  currentTopic (state, update) {
    state.topic_id = update
  },
  currentTopicInfo (state, update) {
    state.topic_info = update
  },
  currentPostId (state, update) {
    state.post_id = update
  },
  currentFeedDetail (state, update) {
    state.feed_detail = update
  },
  topicIndexChange (state, update) {
    state.topicIndex = update
  },
  currentCollectionId (state, update) {
    state.collection_id = update
  },
  currentCollectionInfo (state, update) {
    state.collection_info = update
  }
}

export default ({
  state,
  mutations
})
