import { getProductAgreement } from "@/api/index"
//风险提示等协议
const state = () => ({
    agreement: []
})
const mutations = {
    currentAgreement (state, update) {
      state.agreement = update
    }
}
  
const actions = {
    async getAgreement({ state, commit }) {
        if (state.agreement && state.agreement.length <= 0) {
            const res = await getProductAgreement()
            if (res.status == 0) {
                commit('currentAgreement', res.data)
            }
        }
    }
}

export default ({
    state,
    mutations,
    actions
})
