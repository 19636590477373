import { createStore } from 'vuex'

import commTopic from './modules/commTopic'
import order from './modules/order'
import user from './modules/user'
import share from './modules/share'
import address from './modules/address'
import agreement from './modules/agreement'

export default createStore({
  modules: {
    commTopic,
    user,
    share,
    address,
    agreement,
    order
  }
})
