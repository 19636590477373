import lowerdev from '@/service/lowerdev'
var sensors = require('sa-sdk-javascript')

export const addImpression = function (item) {
  const opt = {
    ad_position: '',
    page_source: '',
    sec_business_line: '',
    picture_id: '',
    item_id: '',
    item_name: '',
    brand: '',
    category: '',
    spu_id: '',
    store_id: '',
    url: '',
    position_source: ''
  }
  Object.assign(opt, item)
  // eslint-disable-next-line
  sensors.track('Impression', opt)
}

export const addPositionClick = function (item) {
  const opt = {
    ad_position: '',
    page_source: '',
    picture_id: '',
    position_source: '',
    item_id: '',
    item_name: '',
    sec_business_line: '',
    category: '',
    product_name: '',
    spu_id: '',
    sku_id: '',
    store_id: '',
    brand: '',
    url: ''
  }
  Object.assign(opt, item)
  // eslint-disable-next-line
  sensors.track('PositionClick', opt, () => {
    console.log(opt)
    if (opt.url) {
      opt.url += opt.url.includes('?') ? '&' : '?'
      location.href = opt.url + 'position_source=' + opt.position_source
    } else if (opt.openUrl_type) {
      console.log('opt.openUrl_type', opt.openUrl_type)
      console.log('opt.openUrl_prop', opt.openUrl_prop)
      console.log('opt.openUrl_render_model', opt.openUrl_render_model)
      lowerdev.openUrl(
        opt.openUrl_type,
        opt.openUrl_prop ? opt.openUrl_prop : '',
        opt.openUrl_render_model ? opt.openUrl_render_model : ''
      )
    }
  })
}

export const addFastSellClick = function (item) {
  const opt = {
    url: '',
    click_content: '',
    page_source: location.href,
    position_source: 'zerobuy'
  }
  Object.assign(opt, item)
  // eslint-disable-next-line
  sensors.track('ZerobuyClick', opt, () => {
    if (opt.url) {
      opt.url += opt.url.includes('?') ? '&' : '?'
      location.href = opt.url + 'position_source=' + opt.position_source
    }
  })
}
