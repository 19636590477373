import { get } from "@/utils/apiBase.js";

// 获取通用省市数据
const getCommonProvinceList = (option) => {
  return get("/apis/address/get_nad", option);
};

// 根据市ID获取区数据
const getCommonCoutyList = (option) => {
  return get("/apis/address/get_als", option);
};

// 根据区ID获取街道数据
const getCNStreetList = (option) => {
  return get("/apis/address/get_address_street", option);
};

export default {
  getCommonProvinceList,
  getCommonCoutyList,
  getCNStreetList,
};
