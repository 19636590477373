import { get, post } from '@/utils/apiBase.js'
// 下单页
// 获取地址列表
const getAddressList = option => {
  return get('/apis/address/get_address_list', option)
}
// 获取默认接口地址
const getDefaultAddress = option => {
  return get('/apis/address/get_default_address', option)
}
// 检测是否是老客
const checkMember = option => {
    return get('/member/check', option)
} 
// 回报档优惠
const mdorderCoupon = option => {
    return get('/apis/mdorder/coupon', option)
} 
// 获取邮费信息
const getRewardsPostage = option => {
  return get('/product/rewards/postage', option)
}
// 创建订单
const CreateProjectOrder = option => {
  return post('/apis/mdorder/create_order', option)
}
// 发起三方支付
const orderSendpay = option => {
  return post('/mall/order/sendpay', option)
}

// 支付查询
const orderPayinfo = option => {
  return get('/apis/mdorder/payinfo', option)
}

// 众筹支付成功获取CP信息
const proPaySuccessCPInfo = option => {
  return get('/apis/project/pro_base_info', option)
}
// 支付方式集合
const getPayMethodList = option => {
  return get('/apis/mdorder/pay_method_list', option)
}
export default {
    getAddressList,
    getDefaultAddress,
    checkMember,
    getRewardsPostage,
    CreateProjectOrder,
    orderSendpay,
    mdorderCoupon,
    orderPayinfo,
    proPaySuccessCPInfo,
    getPayMethodList
}
