import { get, post } from '@/utils/apiBase.js'

// 任务列表
const getTaskList = option => {
    return get('/apis/project/cp/get_task_list', option)
}

// 视频教程任务
const videoStyduSuccess = option => {
    return post('/apis/project/cp/complete_task', option)
}

// 领取金币
const receiveTaskGold = option => {
    return post('/apis/project/cp/receive_coins', option)
}

//金币余额
const getGoldNum = option => {
    return get('/wallet/apis/coin/balance', option)
}

//金币明细
const getGoldHistoryList = option => {
    return get('/wallet/apis/coin/history', option)
}

//获取限时活动列表 
const getActivityTaskList = option => {
    return get('/apis/project/cp/get_activity_task_list', option)
}

// 获取最近的一个项目信息
const getRedNoteProInfo = option => {
    return get('/apis/project/cp_admin/rednote_task_pro_info', option)
}

export default {
    getTaskList,
    videoStyduSuccess,
    receiveTaskGold,
    getGoldNum,
    getGoldHistoryList,
    getActivityTaskList,
    getRedNoteProInfo
}