import Layout from '@/layout/default'

const userRouter = {
  // 个人中心
  path: '/user',
  name: 'user',
  component: Layout,
  children: [
    {
      path: 'center', //个人中心
      meta: {
        isShowHeader: false,
        isShowNav: true,
        isShowBtn: true
      },
      name: 'user-center',
      component: () => import('@/views/user/center.vue')
    },
    {
      path: 'homepage/:id', //个人中心
      name: 'user-homePage',
      meta: {
        isShowBtn: true
      },
      component: () => import('@/views/user/homePage.vue')
    },
    {
      path: 'like_project', //关注的项目
      name: 'user-likeProduct',
      component: () => import('@/views/user/like_project.vue')
    },
    {
      path: 'support_project', //支持的项目
      name: 'user-bayProduct',
      component: () => import('@/views/user/support_project.vue')
    },
    {
      path: 'follow', //关注的人
      name: 'user-likePeople',
      component: () => import('@/views/user/follow.vue')
    },
    {
      path: 'license', //经营资质
      name: 'user-license',
      component: () => import('@/views/user/license.vue')
    },
  ]
}

export default userRouter
