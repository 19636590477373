import Layout from '@/layout/default'
const orderRouter = {
  // 活动
  path: '/order',
  name: 'order',
  component: Layout,
  children: [
    {
      path: 'payment/success',
      name: '支付成功',
      meta: {
        title: '支付成功',
        isShowHeader: true,
      },
      component: () => import('@/views/order/success.vue')
    },
    {
      path: 'gold_pay_success',
      name: '点击计划支付成功',
      meta: {
        title: '支付成功',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/order/gold_pay_success.vue')
    },
    {
      path: 'order_list',
      name: '订单列表',
      meta: {
        title: '我的订单',
        isShowNav: true
      },
      component: () => import('@/views/order/index.vue')
    },
    {
      path: 'after_sale_list',
      name: '退款售后',
      meta: {
        title: '退款售后',
        isShowNav: true,
        isShowHeader: true,
      },
      component: () => import('@/views/order/after_sale_list.vue')
    },
    {
      path: 'project_order_detail',
      name: '订单详情',
      meta: {
        isShowNav: true,
        title: '订单详情'
      },
      component: () => import('@/views/order/details.vue')
    },
    {
      path: 'product_order_detail',
      name: '电商订单详情',
      meta: {
        isShowNav: true,
        title: '订单详情'
      },
      component: () => import('@/views/order/detailsShop.vue')
    },
    {
      path: 'logistics/:orderid',
      name: '查看物流',
      meta: {
        isShowNav: false,
        title: '查看物流'
      },
      component: () => import('@/views/order/logistics.vue')
    },
    {
      path: 'logistics/info',
      name: '物流信息',
      meta: {
        isShowNav: false,
        title: '物流信息'
      },
      component: () => import('@/views/order/logisticsInfo.vue')
    },
    {
      path: 'logistics/add',
      name: '填写退货物流',
      meta: {
        isShowNav: false,
        title: '填写退货物流'
      },
      component: () => import('@/views/order/logisticsAdd.vue')
    }
  ]
}

export default orderRouter
