import Layout from '@/layout/default'
import RouterView from '@@/RouterView' 

const activitysRouter = {
  path: '/mdactivity',
  name: 'mdactivity',
  component: Layout,
  children: [
    {
        path: 'fastsell', // 该目录下为全面屏活动
        name: 'fastsell',
        component: RouterView,
        children: [
            {
                path: 'recommend', // 推荐有礼
                name: 'recommend',
                meta: {
                isShowHeader: true,
                isShowNav: true
                },
                component: () => import('@/views/activity/recommend.vue')
            },
            {
                path: 'invited', // 邀请函
                name: 'invited',
                meta: {
                isShowHeader: true,
                isShowNav: true
                },
                component: () => import('@/views/activity/invited.vue')
            },
            {
                path: 'invited_ewm', // 邀请函 -- 添加客服
                name: 'invited_ewm',
                meta: {
                isShowHeader: true,
                isShowNav: true
                },
                component: () => import('@/views/activity/invited_ewm.vue')
            },
            {
                path: 'akb48', // akb48活动页
                name: 'akb48',
                meta: {
                    isShowHeader: true,
                    isShowNav: true
                },
                component: () => import('@/views/activity/akb48.vue')
            },
            {
                path: 'merchant_day', // 商家感恩盛典
                name: 'merchant_day',
                meta: {
                    isShowHeader: true,
                    isShowNav: true
                },
                component: () => import('@/views/activity/merchant_day.vue')
            },
            {
                path: "miner/rank",
                name: "miner/rank",
                meta: {
                    isShowHeader: true,
                    title: '矿工财富榜',
                    isShowNav: true,
                },
                component: () => import("@/views/activity/miner_rank.vue"),
            },
        ],
    },
    {
        path: 'tenth', // 十周年
        name: 'tenth',
        meta: {
            title: '商家感恩盛典',
            isShowHeader: true,
            isShowNav: true
        },
        component: () => import('@/views/activity/tenth.vue')
    },
    {
        path: 'tenth_rule', // 十周年
        name: 'tenth_rule',
        meta: {
            isShowHeader: true,
            title: '规则说明',
            isShowNav: true
        },
        component: () => import('@/views/activity/tenth_rule.vue')
    },
      {
          path: 'merchant_day_rule', // 摩点商家感恩盛典活动规则
          name: 'merchant_day_rule',
          meta: {
              isShowHeader: true,
              title: '活动规则',
              isShowNav: true
          },
          component: () => import('@/views/activity/merchant_day_rule.vue')
      },
      {
          path: 'miner_rule', // 挖矿排行榜活动说明
          name: 'miner_rule',
          meta: {
              isShowHeader: true,
              title: '活动说明',
              isShowNav: true
          },
          component: () => import('@/views/activity/miner_rule.vue')
      },
      {
          path: "miner/history_rank",
          name: "miner/history_rank",
          meta: {
              isShowHeader: true,
              title: '往期矿工财富榜',
              isShowNav: true,
          },
          component: () => import("@/views/activity/miner_history_rank.vue"),
      },
  ]
}

export default activitysRouter
