import { get, post } from '@/utils/apiBase.js';

// 邀请加入项目
// 获取邀请信息详情
const getProInvitationInfo = (option) => {
  return get('/apis/product/pro_invitation_info', option);
}

// 接受项目发起人邀请
const AcceptProjectInvitation = (option) => {
    return post('/apis/product/accept_project_invitation', option);
}

// 获取用户信息 --- 简洁版
const FrontGetUserInfo = (option) => {
  return get('/apis/user/user_info', option);
}


export default {
    getProInvitationInfo,
    AcceptProjectInvitation,
    FrontGetUserInfo
};
