import Layout from '@/layout/default'

const activityRouter = {
  path: '/hd',
  name: 'hd',
  component: Layout,
  children: [
    {
      path: 'card_activity',
      name: 'index',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/activity/activity.vue')
    },
    {
      path: 'container_list',
      name: 'container_list',
      meta: {
        isShowHeader: true,
        //isShowNav: true
      },
      component: () => import('@/views/activity/container_list.vue')
    }
  ]
}

export default activityRouter
