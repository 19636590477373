import { get, post } from '@/utils/apiBase.js'
// 获取历史创新的内容
const getHistoryIdeaContent = option => {
  return get('/apis/project/history_idea_content', option)
}
// 获取创意详情
const getIdeaIndexInfo = option => {
  return get('/apis/project/idea_index_info', option)
}
// 获取更新列表
const getIdeaUpdateList = option => {
  return get('/apis/project/idea_update_list', option)
}
// 创意订阅操作接口
const postIdeaSubscribe = option => {
  return post('/apis/project/idea_subscribe', option)
}
// 创意点赞接口
const postIdeaFavor = option => {
  return post('/apis/project/creative_idea_favor', option)
}

// 请求针对CP关注关系
const getRelationInfo = option => {
  return get('/apis/user/relation/info', option)
}
// 关注
const postUserFollow = option => {
  return post('/apis/comm/user/follow', option)
}
// 置顶评论
const getRecommentReplyList = option => {
  return get('/apis/mdcomment/get_recomment_reply_list', option)
}
// 获取评论
const getReplyList = option => {
  return get('/apis/mdcomment/get_reply_list', option)
}
// 获取认证信息
const getQueryinfo = option => {
  return get('/apis/project/get_release_item_status', option)
}
// 获取创意类别列表
const getIdeaCategoryList = option => {
  return get('/apis/project/cp/get_idea_category_list', option)
}
// 发布创意
const createIdea = option => {
  return post('/apis/project/cp/create_idea', option)
}
export default {
    getHistoryIdeaContent,
    postIdeaSubscribe,
    getIdeaIndexInfo,
    getIdeaUpdateList,
    postIdeaFavor,
    getRelationInfo,
    postUserFollow,
    getRecommentReplyList,
    getReplyList,
    getQueryinfo,
    getIdeaCategoryList,
    createIdea
}
