import { get, post } from '@/utils/apiBase.js'

// 获取人脸认证结果
const facePrintAuthResult = option => {
  return post('/apis/user_auth_account/face_print_auth_result', option)
}
// 获取个人中心经营资质文件
const userCenterMaterial = option => {
  return get('/apis/product/user_center_material', option)
}
// 身份证信息验证
const personIdMatchResult = option => {
  // return post('/apis/user_auth_account/person_id_match_result', option)
  return post('/apis/userauth/personal/easy', option)
}
export default {
  facePrintAuthResult,
  userCenterMaterial,
  personIdMatchResult
}
