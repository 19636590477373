import Layout from '@/layout/default.vue';
const fastsellRouter = {
  // 预言家
  path: '/lottery',
  name: 'lottery',
  component: Layout,
  children: [
    {
      path: 'fastsell/index/:id',
      name: 'lottery-index',
      meta: {
        title: '大预言家',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/lottery/index.vue'),
    },
    {
      path: 'list',
      name: 'lottery-list',
      meta: {
        title: '预言家列表',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/lottery/list.vue'),
    },
    {
      path: 'rule',
      name: 'lottery-rule',
      meta: {
        title: '预言家规则',
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import('@/views/lottery/rule.vue'),
    }
  ],
};

export default fastsellRouter;
