import Layout from '@/layout/default.vue';
const scoreRouter = {
  // 积分
  path: "/score",
  name: "score",
  component: Layout,
  children: [
    {
      path: "rule",
      name: "score-rule",
      meta: {
        title: "积分说明",
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/score/rule.vue"),
    },
    {
      path: "task/index",
      name: "任务中心",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/task/index.vue"),
    },
    {
      path: "task/video_guide",
      name: "教学视频",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/task/video_guide.vue"),
    },
    {
      path: "task/share_guide",
      name: "提示",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/task/share_guide.vue"),
    },
    {
      path: "task/rule",
      name: "任务规则说明",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/task/rule.vue"),
    },
    {
      path: "gold/list",
      name: "明细",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/gold/list.vue"),
    },
    {
      path: "promotion/index",
      name: '推广中心',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/index.vue"),
    },
    {
      path: "promotion/demo",
      name: '案例说明',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/demo.vue"),
    },
    {
      path: "promotion/sms_demo",
      name: '教程',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/sms_demo.vue"),
    },
    {
      path: "promotion/sms_video_guide",
      name: "短信教学视频",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/sms_video_guide.vue"),
    },
    {
      path: "promotion/detail/:id",
      name: '推广服务',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/detail.vue"),
    },
    {
      path: "promotion/user_detail/:id",
      name: '推广详情',
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/user_detail.vue"),
    },
    {
      path: "promotion/rule",
      name: "推广规则说明",
      meta: {
        isShowHeader: true,
        isShowNav: true,
      },
      component: () => import("@/views/promotion/rule.vue"),
    },
  ],
};

export default scoreRouter;
