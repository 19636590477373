import { post, get } from '@/utils/apiBase.js';

// 领取金币
const receiveCoins = (option, headers) => {
    return post('/apis/project/cp/receive_coins', option, headers);
};

// 获取邀请cp页信息
const getInviteCpDetail = (option, headers) => {
    return get('/apis/project/invite_cp_detail', option, headers);
};

// 接受邀请
const AcceptInviteNewCp = (option, headers) => {
    return post('/apis/project/accept_invite_new_cp', option, headers);
};

// 获取邀请的新cp列表
const getProInviteCpList = (option, headers) => {
    return get('/apis/project/cp/pro_invite_cp_list', option, headers);
};

// 校验是否能发起邀请
const checkInviteCp = (option, headers) => {
    return get('/apis/project/cp/check_invite_cp', option, headers);
};



export default {
    receiveCoins,
    getInviteCpDetail,
    AcceptInviteNewCp,
    getProInviteCpList,
    checkInviteCp
}
