import { createRouter, createWebHistory } from 'vue-router'

const ctx = require.context('./modules', true, /\.js$/)
const modulesList = []
for (const key of ctx.keys()) {
  modulesList.push(ctx(key).default)
}

const routes = modulesList

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  savedPosition() {
    return { left: 0, top: 0 }
  }
})

export default router
