import { get, post } from '@/utils/apiBase.js';

// 获取售后详情
const mdaftersalDetail = (option) => {
    return get('/apis/mdaftersale/order_detail', option);
};

// 查询-撤销售后查询剩余可售后次数
const mdaftersaleLeftNum = option => {
    return get('/apis/mdaftersale/order_close_num', option)
}

// 撤销申请、申诉
const mdaftersaleCancel = (option) => {
    return post('/apis/mdaftersale/cancel', option);
};
// 售后单列表
const mdaftersaleOrders = (option) => {
    return get('/apis/mdaftersale/orders', option);
};
// 上传物流信息
const mdaftersaleUploadExpress = (option) => {
    return post('/apis/mdaftersale/upload_express', option);
};

// 获取快递物流---发货|退货
const mdaftersaleGetLogistics = (option) => {
    return get('/apis/mdaftersale/logistics', option);
};

// 申请售后
const mdaftersaleApply_order = (option) => {
    return post('/apis/mdaftersale/apply_order', option);
};
// 申请申诉
const mdaftersaleAppeal_order = (option) => {
    return post('/apis/mdaftersale/appeal_order', option);
};
// 获取视频
const showVideoList = (option) => {
    return post('/apis/upload/mdmedia/show_video_list', option);
};

export default {
    mdaftersalDetail,
    mdaftersaleCancel,
    mdaftersaleLeftNum,
    mdaftersaleUploadExpress,
    mdaftersaleGetLogistics,
    mdaftersaleApply_order,
    showVideoList,
    mdaftersaleAppeal_order,
    mdaftersaleOrders
};
