import Layout from '@/layout/default'

const popRouter = {
  // 店铺
  path: '/pop',
  name: 'pop',
  component: Layout,
  children: [
    {
      path: 'license', //经营资质
      name: 'pop-license',
      meta: {
        isShowNav: true,
      },
      component: () => import('@/views/pop/license.vue')
    },
  ]
}

export default popRouter
