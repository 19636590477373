import Layout from '@/layout/default'
const addressRouter = {
  // 地址
  path: '/address',
  name: 'address',
  component: Layout,
  children: [
    {
      path: 'address_list',
      name: '地址列表',
      meta: {
        title: '地址列表'
      },
      component: () => import('@/views/address/index.vue')
    },
    {
      path: 'add_address',
      name: '地址详情',
      meta: {
        title: '地址详情'
      },
      component: () => import('@/views/address/add_address.vue')
    },
    {
      path: 'add_address/:id',
      name: '编辑地址',
      meta: {
        title: '编辑地址'
      },
      component: () => import('@/views/address/add_address.vue')
    }
  ]
}

export default addressRouter
